import React, { useEffect, useState, useMemo, useRef } from 'react';

import moment from 'moment';

import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';

import EmailIcon from '@material-ui/icons/Email';
import WarningIcon from '@material-ui/icons/Warning';

import validator from 'email-validator';


import {
  getAllContacts,
  createContact,
  validatePhone,
  deleteContact,
  updateContact,
  getAppointmentForDate
} from '../../../../Services/API';


import Table from '../../../../Components/Table/Table';

import Contact from '../../../../Components/Contact/ContactLarge';
import {getInitials} from '../Inbox/ChatList';
import ModalBase from '../../../../Components/Dialogs/ModalBase';
import CreateContact from '../../Dialogs/CreateContact/CreateContact';
import BulkEmailMessage from '../../Dialogs/BulkMessage/BulkEmailMessage';
import AppHeader from '../../../../Components/AppHeader/AppHeaderLarge';


const Contacts = props => {

  const { token, user } = props;
  const [contacts, setContacts] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);
  const [showCreateContact, setShowCreateContact] = useState(false);
  const [bulkEmail, setBulkEmail] = useState({show: false});
  const [appointments, setAppointments] = useState([]);
  const [showDataErrors, setShowDataErrors] = useState(false);

  const latestAppointments = useMemo(()=> {

    if(appointments.length === 0) {
      return {};
    }

    return appointments.reduce((reducer, val) => {

      if (reducer[val.customerId]) {
        if (reducer[val.customerId].created < val.created) {
          reducer[val.customerId] = val;
        }
      }
      else {
        reducer[val.customerId] = val;
      }
      return reducer;
    })
  },[appointments]);


  const contactList = useMemo(()=> {

    if(!contacts) {
      return [];
    }

    return Object.keys(contacts).sort().reduce( (acc, key) => {

      contacts[key].forEach(contact=> {
        if(latestAppointments[contact.id]) {
          contact.appointmentStatus = latestAppointments[contact.id].status;
        }
        contact.dataError = !validator.validate(contact.email);
      });

      acc = [...acc, ...contacts[key]];
      return acc;
    }, []);
  },[contacts]);

  const refreshContacts = () => {
    getAllContacts(token).then(r=> {
      if(r.status === 'ok') {
        setContacts(r.customers);
      }
    });
  };

  useEffect(()=> {
    getAppointmentForDate(token,null,null).then(res=> {
      if(res.status === 'ok') {
        setAppointments(res.appointments);
        refreshContacts();
      }
    });
  }, [token]);

  const selectContact = (contact, toggle) => {
    setSelectedContact(contact);
  };

  const sentEmail = ()=> {
    alert('Messages sent');
    setBulkEmail({show: false});
  }

  const getCellBackground = (isError) => {
    if(!showDataErrors || !isError) {
      return 'inherit';
    }

    return '#efb5b5';
  }

  return (
    <div style={{padding: '10px'}}>

      <Dialog style={{minHeight: '600px'}} fullWidth maxWidth='lg'  open={selectedContact !== null} onClose={()=>selectContact(null)}>
        { selectedContact && <Contact showXl token={token} initials={getInitials(selectedContact.name)} contact={selectedContact} chatId={selectedContact.id} /> }
      </Dialog>

      <Dialog fullScreen disableBackdropClick disableEscapeKeyDown style={{minHeight: '800px'}} fullWidth maxWidth='lg'  open={bulkEmail.show} onClose={()=>setBulkEmail({show: false})}>
        <BulkEmailMessage emails={bulkEmail.emails} token={token} onClose={()=>setBulkEmail({show:false})} onEmailSent={sentEmail} />
      </Dialog>

      <ModalBase title='Add Lead' open={showCreateContact} actions={<Button onClick={()=>setShowCreateContact(false)}>Close</Button>}>
        <CreateContact token={token} user={user} onSave={refreshContacts}/>
      </ModalBase>

      <AppHeader onNew={()=>{setShowCreateContact(true)}} label='New Lead' title={'Leads'}/>

      <Table
        title='Leads'
        options={{
          filtering: true,
          selection: true,
          pageSize: 25,
          pageSizeOptions: [25,50,100],
          exportButton: true,
          exportAllData: true,
          rowStyle: rowData => ({backgroundColor: getCellBackground(rowData.dataError)})
        }}
        actions={[
        {
          tooltip: 'Email these leads',
          icon: ()=><EmailIcon/>,
          onClick: (evt, data) => {
            const emails = data.map(d=>d.email).filter(d=>{if(d) {return true;}});
            if(emails.length === 0){
              alert('No leads selected with valid emails');
              return;
            }

            setBulkEmail({show: true, emails});
          }
        },
        {
          tooltip: 'Show Errors',
          icon: ()=><WarningIcon/>,
          onClick: (evt, data) => {
            setShowDataErrors(r=>!r);
          },
          isFreeAction: true
        }
        ]}
        columns={[
          {title: 'Name',field: 'rawName', filtering: false},
          {title: 'Email',field: 'email', filtering: false},
          {title: 'Phone',field: 'phone', editable: 'onAdd', filtering: false},
          {title: 'Appointment Status',field: 'appointmentStatus', editable: 'never'},
          {title: 'Created',field: 'created', editable: 'never', filtering: false, render: rowData => <span>{rowData.created ? moment(rowData.created).format('lll') : ''}</span>},
          {title: 'Source',field: 'source', editable: 'never'},
        ]}
        data={contactList}
        onRowClick={ (e,d,t) => { selectContact(d,t); }}
        editable={{
          onRowAddCancelled: rowData => console.log("Row adding cancelled"),
          onRowUpdateCancelled: rowData => console.log("Row editing cancelled"),
          onRowAdd: newData => {

              return validatePhone(token,newData.phone).then(valid=> {
                if(!valid.phone) {
                  throw new 'error';
                }

                return createContact(token,newData.rawName, newData.email, valid.phone).then(r=> {
                  refreshContacts();
                });
              })
          },
          onRowUpdate: (newData, oldData) => {
            return updateContact(token, newData.id, newData.rawName, newData.email).then(r=>refreshContacts());
          },

          onRowDelete: oldData => {
            return deleteContact(token,oldData.id).then(r=>refreshContacts());
          }

        }}
      />


    </div>
  );

};

export default Contacts