import React, {useState, useEffect, useMemo, useContext } from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from "react-router-dom";

import ButtonAppBar from '../../Components/AppBar/ButtonAppBar';
import BrowserNotification from '../../Components/BrowserNotification/BrowserNotification';
import Menu from './Menu/MenuLarge';


import { Alert, AlertTitle } from '@material-ui/lab';
import Dialog from '@material-ui/core/Dialog';

import { makeStyles } from '@material-ui/core/styles';

import AccountChooser from './Dialogs/AccountChooser/AccountChooser';

import Inbox from './Apps/Inbox/InboxLarge';
import Contacts from './Apps/Contacts/ContactsLarge';
import Appointments from './Apps/Appointments/AppointmentsLarge';
import SocketProvider from '../../Context/SocketContext';
import Automations from './Apps/Automations/AutomationsLarge';
import Settings from './Apps/Settings/Settings';

import { AlertContext } from '../../Context/AlertContext';

import {
  getAccounts as getAccountsApi,
  getUser as getUserApi,
  getTokenForAccount as getTokenForAccountApi,
}
from '../../Services/API';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  main: {
    display: 'flex',
    marginTop: 10,
    flexGrow: 1,
  },
  menu: {
    width: 240,
    flexShrink: 0
  },
  content: {
    flexGrow: 1
  }
}));

const MainLarge = ({token, onLogout, isPastDue}) => {

  const [accountData,setAccountData] = useState({token});
  const [showAccountChooser, setshowAccountChooser] = useState(false);

  const classes = useStyles();

  const alertCtx = useContext(AlertContext);

  useEffect(()=> {
    refreshAccountData();
  },[accountData.token]);

  useEffect(()=> {

    if(isPastDue) {
      alertCtx.showAlert('Your account is past due. To avoid interruption of service, update your payment information in the settings page.');
    }

  },[isPastDue]);


  const getTokenForAccount = accountId => {

    getTokenForAccountApi(token, accountId).then(r=> {
      if(r.status === 'ok') {
        setAccountData({
          ...accountData,
          user: r.user,
          token: r.token,
        });
        setshowAccountChooser(false);
      }
    });
  };

  const refreshAccountData = () => {
      Promise.all([getUserApi(accountData.token),
         getAccountsApi(accountData.token)]).then( res => {
           setAccountData({
             user: res[0].user,
             accounts: res[1].accounts,
             token: accountData.token,
           });
         });
  }

  const menuItems = [
    {
      name: 'Switch Accounts',
      handler: ()=>{setshowAccountChooser(true)}
    },
    {
      name: 'Logout',
      handler: ()=>{onLogout()}
    },


    ];

  const titleInfo = useMemo(()=> {
    if(!accountData.user) {
      return {name: '', company: ''};
    }
    return {name: accountData.user.name, company: accountData.user.account.company};
  },[accountData.user]);

  return (
    <div className={classes.root}>

      <Dialog style={{minHeight: '600px'}} fullWidth maxWidth='lg'  open={showAccountChooser} onClose={()=>setshowAccountChooser(false)}>
        {
        accountData.accounts && <AccountChooser accounts={accountData.accounts} accountSelected={getTokenForAccount}/>
      }
     </Dialog>

      <Router>
        <SocketProvider token={accountData.token}>
          <BrowserNotification/>
          <ButtonAppBar name={titleInfo.name} accountName={titleInfo.company} menuItems={menuItems}/>

          { alertCtx.alertMessage && <Alert style={{marginTop: '10px'}} severity="warning">{alertCtx.alertMessage}</Alert> }

          <div className={classes.main}>
            <div className={classes.menu}>
              <Menu/>
            </div>
            <div className={classes.content}>
            <Switch>
              <Route exact path="/">
                <Redirect to="/messages" />
              </Route>
              <Route exact path="/messages" render={()=><Inbox token={accountData.token} user={accountData.user}/>} />
              <Route exact path="/appointments" render={()=><Appointments token={accountData.token} user={accountData.user}/>} />
              <Route exact path="/leads" render={()=><Contacts token={accountData.token}/>} />
              <Route exact path="/automations" render={()=><Automations token={accountData.token}/> } />
              <Route exact path="/settings" render={()=><Settings account={accountData} />} />
            </Switch>
            </div>
          </div>
        </SocketProvider>
      </Router>
    </div>
  );
};

export default MainLarge;