import React, { Fragment, useState } from 'react';

import { TextField, Button } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import {updateContact as updateContactApi } from '../../../../Services/API';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(2)
  },
  textField: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
}));

const EditContact = props => {


  const { contact, token } = props;

  const [name, setName] = useState(contact.name);
  const [email, setEmail] = useState(contact.email);


  const classes = useStyles();

  if (!contact) {
    return '';
  }

  const saveContact = () => {
    updateContactApi(token, contact.id, name, email, contact.labels).then(results => {
      if (results.status !== 'ok') {
        console.error('error saving contact');
      }
      props.handleClose();
    });
  };

  return (

    <Fragment>
        <TextField
          className={classes.textField}
          variant='outlined'
          value={name}
          label='Name'
          margin='normal'
          onChange={e=>{setName(e.target.value)}}
        />
        <TextField
          className={classes.textField}
          variant='outlined'
          value={email}
          label='Email'
          onChange={e=>{setEmail(e.target.value)}}/>
        <TextField
          className={classes.textField}
          variant='filled'
          InputProps={{
            readOnly: true,
          }}
          defaultValue={contact.phone}
          label='Phone'/>
        <TextField
          className={classes.textField}
          variant='filled'
          InputProps={{
            readOnly: true,
          }}
          defaultValue={contact.source}
          label='Source'/>
        <TextField
          className={classes.textField}
          variant='outlined'
          InputProps={{
            readOnly: true,
          }}
          label='AutoRun ID'/>
        <Button color='primary' className={classes.textField} variant='contained' onClick={saveContact}>Save</Button>
        <Button className={classes.textField} variant='text' onClick={props.handleClose}>Cancel</Button>
      </Fragment>
  );
};

export default EditContact;
