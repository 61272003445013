/* global localStorage */
import React, {useState} from 'react';

import { Button, InputAdornment, OutlinedInput } from '@material-ui/core';

const STATE_VARIABLE = '_message_state';

function saveStateLocal(state) {
  localStorage.setItem(STATE_VARIABLE, state);
}

function clearState() {
  localStorage.removeItem(STATE_VARIABLE);
}

function loadState() {
  let state = localStorage.getItem(STATE_VARIABLE);
  if (!state) {
    return '';
  }
  return state;
}


const ChatMessageBar = props => {

    const [text, setText] = useState(loadState());

    function setTextState(t) {
      saveStateLocal(t);
      setText(t);
    }

    function sendMessage() {
      props.sendMessage(text);
      setTextState('');
    }

    return (
      <OutlinedInput fullWidth variant='outlined'
      value={text}
      onChange={e=>setTextState(e.target.value)}
      endAdornment={
          <InputAdornment position="end">
            <Button disabled={props.disabled} color='primary' variant='contained' onClick={sendMessage}>Send</Button>
          </InputAdornment>
        }
    />

  );
};

export default ChatMessageBar;