/* global fetch */

const SERVER_URL = process.env.REACT_APP_BASE_URL;
const useSSL = (process.env.REACT_APP_USE_HTTPS === "true");
export const BASE_URL = `${useSSL ? 'https' : 'http'}://${SERVER_URL}/v1`;
export const SOCKET_URL = `${useSSL ? 'wss' : 'ws'}://${SERVER_URL}`;

const getOptions = (method, body = null) => {

  const options = {
    'method': method,
    headers: {}
  };

  if (body) {
    options.headers = { 'content-type': 'application/json' }
    options.body = body;
  }

  options.headers['mode'] = 'cors';

  return options;
};

const tokenHeader = (opts, token) => {
  opts.headers['authorization'] = `Bearer ${token}`;
  return opts;
};



function _fetchUrl(params) {
  const url = `${BASE_URL}/${params.path}`;
  const options = tokenHeader(getOptions(params.method, params.body), params.token);
  return fetch(url, options).then(r => { const res = r.json(); res.httpStatus = r.status; return res; } );
}

export function get(params) {
  params.method = 'GET';
  return _fetchUrl(params);
}

export function post(params) {
  params.method = 'POST';
  return _fetchUrl(params);
}

export function put(params) {
  params.method = 'PUT';
  return _fetchUrl(params);
}

export function del(params) {
  params.method = 'DELETE';
  return _fetchUrl(params);
}
