import React, { useEffect, useState } from 'react';

import moment from 'moment';

import {
  getSchedules,
  editSchedule,
  publishSchedules,
  deleteSchedule,
} from '../../../../Services/API';

import AppHeader from '../../../../Components/AppHeader/AppHeaderLarge';
import Table from '../../../../Components/Table/Table';
import NewAutomation from './NewAutomation';
import EditAutomation from './EditAutomation';
import AutomationCode from './AutomationCode';
import ModalBase from '../../../../Components/Dialogs/ModalBase';
import EditIcon from '@material-ui/icons/Edit';

import Button from '@material-ui/core/Button';

import SyncIcon from '@material-ui/icons/Sync';
import CircularProgress from '@material-ui/core/CircularProgress';
import CodeIcon from '@material-ui/icons/Code';

const Automations = props => {

  const {token} = props;

  const [schedules, setSchedules] = useState([]);
  const [view, setView] = useState('list');
  const [isPublishing, setIsPublishing] = useState(false);
  const [code, setCode] = useState(null);
  const [editSchedule, setEditSchedule] = useState();

  const refreshSchedules = () => {
      getSchedules(token).then(r=> {
      if(r.status === 'ok') {
        setSchedules(r.schedules);
      }
    });
  };

  const onNewScheduleSuccess = () => {
    setView('list');
    refreshSchedules();
  };

  const onDeleteSchedule = id => {
    return deleteSchedule(token,id).then(r=>refreshSchedules());
  }

  const onPublishClick = () => {
    setIsPublishing(true);
  }

  const onEditEschedule = schedule => {
    setEditSchedule(schedule);
  }

  useEffect(()=> {

    if(isPublishing) {
      publishSchedules(token).then(r=>{
        alert('Schedules published.');
        setIsPublishing(false);
      });
    }
  }, [isPublishing]);

  useEffect(()=> {
    if(editSchedule) {
      setView('edit');
    }
  },[editSchedule])

  useEffect(refreshSchedules,[token]);

  return <div>


    {

    view === 'list'  &&
    <React.Fragment>
     <AppHeader onNew={()=>{setView('new')}} label='New Automation' title={'Automations'}/>
     <ModalBase title='Automation HTML Code' open={code !== null} actions={<Button onClick={()=>setCode(null)}>Close</Button>}>
        { code && <AutomationCode accountId={code.accountId} tag={code.tag}/> }
        </ModalBase>
    <Table
      title='Automations'
      options={{
          pageSize: 10,
          pageSizeOptions: [10,15,20]
        }}
      columns={[
        {title: 'Name',field: 'name'},
        {title: 'Tag',field: 'tag'},
        {title: 'Type',field: 'type', editable: 'never'},
        {title: 'Created',field: 'created', editable: 'never', render: r=><span>{moment(r.created).format('l LT')}</span>},
        {title: 'Master Schedule',field: 'global', editable: 'never'},
      ]}
      data={schedules}
      actions={[{
          icon: ()=> ( isPublishing ? <CircularProgress/> : <SyncIcon/> ),
          disabled:isPublishing,
          tooltip: 'Publish',
          isFreeAction: true,
          onClick: onPublishClick
        },

         {
            onClick: (evt ,data) => setCode(data),
            icon: ()=> <CodeIcon/>,
            tooltip: 'Get HTML Code',
          },
                  {
          icon: ()=> <EditIcon/>,
          tooltip: 'Edit',
          onClick: (evt, data)=> onEditEschedule(data)
        },
        ]}
      editable={{
        onRowDelete: data=> { return onDeleteSchedule(data.id);  }

      }}
      /></React.Fragment> }


      { view === 'new' &&
      <div>
        <NewAutomation token={token} onSuccess={onNewScheduleSuccess} />
       <Button variant='contained' color='primary' onClick={()=>{ setView('list');}}>Cancel</Button>
      </div>
      }

      {
        view === 'edit' &&
        <div style={{padding: '14px'}}>
          <EditAutomation schedule={editSchedule} onCancel={()=>{setEditSchedule(null); setView('list')}} token={token} onSuccess={onNewScheduleSuccess} />
        </div>
      }

    </div>
}

export default Automations;