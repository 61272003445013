import React, { useState, useReducer } from 'react';

import moment from 'moment';

import uuid from 'uuid';

import {
  Stepper,
  Button,
  Step,
  StepLabel,
  StepContent,
  TextField,
  FormHelperText,
  Tabs,
  Tab,
  Paper,
  FormGroup,
  Typography,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';


import { makeStyles } from '@material-ui/core/styles';

import {
  updateSchedule,
} from '../../../../Services/API';


import WeekView from './WeekView';

const useStyles = makeStyles((theme) => ({
  stepControls: {
    marginTop: theme.spacing(1)
  },
  nextButton: {
    marginLeft: theme.spacing(1)
  },
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(1)
  }
}));

const defaultNotifications = {
  texts: {
    signup: 'Hey 👋  $first! Welcome to $gym. I see you just registered for $class and I just wanted to reach out and welcome you to the gym.  💪',
    followup: 'I just wanted to make sure you were able to get on the schedule.  Did you need any help? 🧐',
    noshow: '$first looks like we missed you yesterday, so I went ahead and rescheduled you for today at the same time.  Does that work?',
    reminder: 'Hey $first just a friendly reminder of your $type tomorrow at $time. We are completely full so I just need to make sure you are still able to make it. 🤞',
    day2: 'Sorry I wasn’t able to reach you yesterday $first… when is the best time to reach 📞 you today?',
    day3: '$first did you know $class is a great way to get in shape 💪. What are you looking to accomplish by getting started?',
    day4: '$first, I wasn’t able to get a hold of you, so I went ahead and scheduled you for our next $type. LMK if you can make it! 👍',
    day5: 'Hey $first, I see you weren’t able to make it yesterday.  Will today work or would tomorrow be better?'
  },
  emails: {
    signup: {
      subject: 'Welcome $first to the gym',
      body: `$first,

I just wanted to reach out and say thanks for signing up for $class.

We hope to see you visit the gym a few times during your trial membership.

Please make sure to introduce yourself to us and reach out if you have any questions.

See You Soon!`
    },
    reminder: {
      subject: 'Appointment Reminder',
      body: `$first,

I'm super excited to see you today at $time. Just in case here is our address:

$address

Make sure to show up 15 mins before hand so we can give you a quick tour and answer any questions you may have.

Thanks`
    }
  }
};

const notificationReducer = (state, action) => {

  const ret = {...state};

  switch(action.type) {
    case 'email_body_update':
      ret.emails[action.key].body = action.value;
      break;
    case 'email_subject_update':
      ret.emails[action.key].subject = action.value;
      break;
    case 'email_reset':
      ret.emails[action.key] = defaultNotifications.emails[action.key];
      break;
    case 'text_update':
      ret.texts[action.key] = action.value;
      break;
    case 'text_reset':
      ret.texts[action.key] = defaultNotifications.texts[action.key];
      break;
  }

  return ret;
};

function getCalendarsFromSchedule(schedule) {
  if(Array.isArray(schedule)) {

    return schedule.map(s=>s.name || 'default');

  } else {
    return ['default'];
  }
}

function convertScheduleToEventsArray(schedule) {

  function getEventsFromSchedule(schedule, index) {
    return Object.keys(schedule).reduce( (acc,dayOfWeek)=> {
      const shifts = schedule[dayOfWeek];
      shifts.forEach(shift=> {

        const [start,end] = shift.split(',');

        const evt = {
          id: uuid.v4(),
          start: moment(start, "HH:mm").day(dayOfWeek).toDate(),
          end: moment(end, "HH:mm").day(dayOfWeek).toDate(),
          resourceId: index+1
        };

        acc.push(evt);
      });
      return acc;
    }, []);
  }

  if(Array.isArray(schedule)) {

    let toRet = [];
    schedule.forEach( (s,idx)=> {
      toRet = [...toRet, ...getEventsFromSchedule(s.schedule,idx)];
    });

    return toRet;
  } else {

    return getEventsFromSchedule(schedule, 0);
  }
}

const EditAutomation = props => {

  const [tab, setTab] = useState(0);
  const [state, setState] = useState({
    events: convertScheduleToEventsArray(props.schedule.schedule),
    calendars: getCalendarsFromSchedule(props.schedule.schedule),
  });

  const [notifications, dispatch] = useReducer(notificationReducer, {
    texts: props.schedule.texts,
    emails: props.schedule.email
  });

  const classes = useStyles();

  const onFinish = () => {

    const scheds = state.events.reduce( (red,item)=> {
      const name = state.calendars[item.resourceId-1];

      if(!red[name]) {
        red[name] = {sun:[],mon:[],tue:[],wed:[],thu:[],fri:[],sat:[]};
      }

      const start = moment(item.start);
      const end = moment(item.end);
      red[name][start.format('ddd').toLowerCase()].push(`${start.format('HH:mm')},${end.format('HH:mm')}`);

      return red;
    },{});

    updateSchedule(props.token, props.schedule.id, {
      type: props.schedule.type,
      duration: props.schedule.duration,
      schedules: Object.keys(scheds).map(key=>({name: key, schedule: scheds[key]})),
      closedDates: props.schedule.closedDates,
      texts: notifications.texts,
      email: notifications.emails,
      global: props.schedule.global
    }).then(r=> {
      props.onSuccess();
    });
  }

    const setTabValue = (e,v) => {
    setTab(v);
  }

  const updateEvents = events => {
    setState({
      ...state,
      events,
    });
  }

  return <div  style={{textAlign: 'left'}}>


        <Typography variant='h3'>Edit Automation</Typography>
         <div>
          <Tabs
            value={tab}
            onChange={setTabValue}
          >
            <Tab label='Schedule'/>
            <Tab label='Texts'/>
            <Tab label='Emails'/>
          </Tabs>

          {
            tab === 0 &&
            <Paper square className={classes.paper}>
            <div>
              <WeekView events={state.events} onEventsUpdated={updateEvents} resources={state.calendars} />
            </div>
            </Paper>

          }

          { tab === 1 && <Paper square className={classes.paper}>
            {
              [{
                key: 'signup',
                value: 'Sign Up'
              },
              {
                key: 'followup',
                value: 'Follow Up'

              },
              {
                key: 'noshow',
                value: 'No Show'
              },
              {
                key: 'reminder',
                value: 'Reminder'
              },
              {
                key: 'day2',
                value: 'Day 2'
              },
              {
                key: 'day3',
                value: 'Day 3'
              },
              {
                key: 'day4',
                value: 'Day 4'
              },
              {
                key: 'day5',
                value: 'Day 5'
              }
              ].map(item=> {

                return (
                  <React.Fragment key={item.key}>
                  <Typography variant='subtitle1'>{item.value}</Typography>
                  <TextField
                    value={notifications.texts[item.key]}
                    multiline
                    variant='outlined'
                    fullWidth
                    margin='dense'
                    size='small'
                    onChange={e=>dispatch({type: 'text_update', key: item.key, value: e.target.value})}
                  />
                  <Button color='primary' onClick={()=> {
                    dispatch({type: 'text_reset', key: item.key});
                  }}>Reset</Button>
                  <Typography variant='caption' display='inline'>({notifications.texts[item.key].length})</Typography>
                  </React.Fragment>
                )
              })
            }
            </Paper>
          }
          {
            tab === 2 && <React.Fragment>
               {
              [{
                key: 'signup',
                value: 'Sign Up'
              },
              {
                key: 'reminder',
                value: 'Reminder'
              }
              ].map(item=> {

                return (
                  <Paper square className={classes.paper} key={item.key}>
                  <Typography variant='subtitle1'>{item.value}</Typography>
                  <TextField
                    value={notifications.emails[item.key].subject}
                    variant='outlined'
                    helperText='Enter the subject of your email'
                    fullWidth
                    margin='dense'
                    size='small'
                    onChange={e=>dispatch({type:'email_subject_update', key: item.key, value: e.target.value})}
                  />
                  <TextField
                    value={notifications.emails[item.key].body}
                     multiline
                    variant='outlined'
                    fullWidth
                    margin='dense'
                    size='small'
                    helperText='The body of your email'
                    onChange={e=>dispatch({type:'email_body_update', key: item.key, value: e.target.value})}
                  />

                 <Button color='primary' onClick={()=> {
                    dispatch({type: 'email_reset', key: item.key});
                  }}>Reset</Button>
                  </Paper>
                )
              })
            }
            </React.Fragment>
          }
         </div>
          <div className={classes.stepControls}>
            <Button variant='contained' onClick={props.onCancel}>Cancel</Button>
            <Button className={classes.nextButton} variant='contained' color='secondary' onClick={onFinish}>Save</Button>
          </div>

  </div>


};

export default EditAutomation;