/* global localStorage */

import React, { Component, useEffect, useState, useContext } from 'react';
import './App.css';
import Login from './Pages/Login/Login.js';
import Main from './Pages/Main/Main.js';
import MainLarge from './Pages/Main/MainLarge.js';

import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import  AccountStandingProvider, { Canceled, AccountStandingContext } from './Context/AccountStandingContext';
import AlertProvider, { AlertContext } from './Context/AlertContext';


import { Hidden } from '@material-ui/core';

const TOKEN_KEY = '_token';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#697d91'
    },
    secondary: {
      main: '#ff500c'
    }
  }
});

const AppMain = ({token, saveToken}) => {

  const ctx = useContext(AccountStandingContext);

  if(ctx.standing === 'canceled') {
    return <Canceled onLogout={()=>saveToken(null)}/>
  }

  return (
    <React.Fragment>
      <Hidden mdUp>
        <Main isPastDue={ctx.standing === 'past_due'} token={token} onLogout={()=>saveToken(null)}/>
      </Hidden>
      <Hidden smDown>
        <MainLarge isPastDue={ctx.standing === 'past_due'} token={token} onLogout={()=>saveToken(null)}/>
      </Hidden>
    </React.Fragment>
    );
};

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showLogin: true,
      token: '',
      user: null,
    };
  }

  componentDidMount() {
    const token = localStorage.getItem(TOKEN_KEY);

    if (token) {
      this.setState({
        token: token,
        showLogin: false
      });
    }
  }


  saveToken = (token) => {
    this.setState({
      token,
      showLogin: token ? false : true
    }, () => {
      if (token) {
        localStorage.setItem(TOKEN_KEY, token);
      }
      else {
        localStorage.removeItem(TOKEN_KEY);
      }
    });
  }

  render() {

    const { showLogin, token } = this.state;

    return (
      <div className="App">
        <ThemeProvider theme={theme}>
        <AlertProvider>
        { showLogin ? <Login onSuccess={token=>this.saveToken(token)} /> :
            <AccountStandingProvider token={token}>
              <AppMain token={token} saveToken={this.saveToken}/>
            </AccountStandingProvider>
        }
        </AlertProvider>
        </ThemeProvider>
      </div>
    );
  }
}

export default App;
