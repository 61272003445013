import React, { Fragment } from 'react';

import ChatList from './ChatList';

const Inbox = props => {

  const { token, chats, newMessage } = props;

  return (
    <Fragment>
      <ChatList token={token} onAdd={newMessage} messages={chats} onChatClick={id=>props.onMessageClick(id)} />
    </Fragment>
  );

};

export default Inbox;
