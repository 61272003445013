import React, { useState, useEffect, useMemo } from 'react';

import { Paper,Box, Typography, TextField, Button, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';

import {
  updatePassword as updatePasswordApi,
  updateAddress as updateAddressApi,
  getAddress as getAddressApi,
  getBilling,
  addCreditCard,
} from '../../../../Services/API';

import {Elements, CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const useStyles = makeStyles(theme => ({
  box: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
}));

const addressKeys = [
    {
      key:"company",
      value: "Name"
    },
    {
      key:"addressLine1",
      value:"Line 1"
    },
    {
      key:"addressLine2",
      value:"Line 2",
      optional: true,
    },
    {
      key:"addressLine3",
      value:"Line 3",
      optional: true,
    },
    {
      key:"addressCity",
      value:"City"
    },

    {
      key:"addressPostalCode5",
      value:"Post Code"
    },
    {
      key:"addressRegion",
      value:"Region/State",
      readonly: true,
    },
    {
      key:"addressCountry",
      value:"Country",
      readonly: true,
    },

    {
      key:"phone",
      value:"Phone"
    },
    {
      key:"timezone",
      value:"Timezone",
      readonly: true,
    },
];

const SettingsBase = props => {

  const { account } = props;
  const { user } = account;

  const [password, setPassword] = useState({one: '', two: ''});
  const [location, setLocation] = useState(null);
  const [billing, setBilling] = useState({invoices: null, paymentMethods: []});
  const [errors, setErrors] = useState({});
  const [showStripe, setShowStripe] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [showPastDueAlert, setShowPastDueAlert] = useState(false);
  const [stripeError, setStripeError] = useState('');

  const stripe = useStripe();
  const elements = useElements();

  const isAdmin = useMemo(()=> {

    if(!user) {
      return false;
    }

    return user.role === 'sysadmin' || user.role === 'accountowner';
  },[user])

  const refreshAddress = (token) => {

    if(!token) { return; }

    getAddressApi(token).then(r=> {
      if(r.status === 'ok') {
        setLocation(r.address);
      }
    });
  };

  const refreshBilling = (token)=> {
    if(!token) { return;}

    getBilling(token).then(r=> {
      if(r.status === 'ok') {
        setBilling({
          invoices: r.invoices,
          paymentMethods: r.payments,
        });
      }
    });
  }

  useEffect(()=> {

    if(!billing.invoices) {
      return;
    }

    const {invoices} = billing;

    if(invoices.payment_intent
      && invoices.payment_intent.last_payment_error
      && invoices.paid === false
      && invoices.attempted === true
    ) {
      setShowPastDueAlert(true);
    } else {
      setShowPastDueAlert(false);
    }
  },[billing.invoices])

  useEffect(()=> {
    refreshAddress(account.token);
    refreshBilling(account.token);
  },[account.token]);


  useEffect(()=> {
    if(!processing) {
      return;
    }

    updateCreditCard();
  },[processing]);


  const classes = useStyles();

  const setPasswordValue = (key) => (e) => {
    const value = e.target.value;
    setPassword(item => {
      item[key] = value;
      return {...item};
    });
  };
  const setLocationValue = (key) => (e) => {

    const value = e.target.value;

    setLocation(loc=> {
      loc[key] = value;
      return {...loc};
    });
  }


  const updatePassword = ()=> {
    let hasErrors = false;

    if(!password.one) {
      setErrors(err => {
        err['one'] = true;
        return {...err};
      });
      hasErrors = true;
    }

    if(!password.two) {
      setErrors(err=> {
        err['two'] = true;
        return {...err};
      });
      hasErrors = true;
    }

    if(password.two !== password.one) {
      setErrors(err=> {
        err['two'] = true;
        return {...err};
      });
      hasErrors = true;
    }

    if(hasErrors) {
      return;
    }

    setErrors({});

    updatePasswordApi(account.token, password.one).then(r=> {
      alert('Password updated');
    });
  }

  const updateLocation = () => {

    updateAddressApi(account.token, location).then(r=> {
      refreshAddress(account.token);
    });
  };

  const formatCard = (data) => {
    const isExpired = data.expYear <= new Date().getYear() && data.expMon <= new Date().getMonth();
    return `${data.card.toUpperCase()} xxxx-xxxx-xxxx-${data.last4}${isExpired ? ' EXPIRED' : ''}`;
  };

  const updateCreditCard = async () => {
    if(!stripe || !elements) {
      return;
    }

    const cardElm = elements.getElement(CardElement);

    const {paymentMethod, error} = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElm
    });

    if(error) {
      setProcessing(false);
      setStripeError(error.message);
      return;
    }

    //todo: only call this once.
    addCreditCard(account.token, paymentMethod.id).then(r=> {
      if(r.status === 'ok') {
        refreshBilling(account.token);
        alert('Payment Updated');

        setShowStripe(false);
        setStripeError('');
        setShowPastDueAlert(false);
      } else {
        setStripeError(r.error.raw.message);
      }

       setProcessing(false);
    });
  };

  return(
     <Paper style={{margin: '10px', padding: '10px', textAlign: 'left'}}>
      <Typography variant='h4' align='center'>Settings</Typography>
      <Paper style={{margin: '10px', padding: '10px'}}>
        <Box display='flex' flexDirection='row'>
       { user &&  <Box className={classes.box} display='flex' flexDirection='column' flexGrow={1}>
          <Typography variant='h5'>User Details</Typography>
          <TextField id="outlined-basic" label="Name" variant="standard" value={user.name} disabled/>
          <TextField id="outlined-basic" label="Email" variant="standard" value={user.email} disabled/>
          <TextField id="outlined-basic" label="Role" variant="standard" value={user.role} disabled/>
          <TextField id="outlined-basic"
            error={errors['one']}
            label="New Password"
            onChange={setPasswordValue('one')}
            variant="outlined"
            type="password"
            value={password.one} />
          <TextField id="outlined-basic"
            error={errors['two']}
            helperText={errors['two'] ? "Passwords don't match" : ''}
            label="New Password (again)"
            onChange={setPasswordValue('two')}
            variant="outlined"
            type="password"
            value={password.two} />
          <Button onClick={updatePassword} color='secondary' variant='outlined'>Update Password</Button>
        </Box> }

      { location &&  <Box className={classes.box} display='flex' flexDirection='column' flexGrow={1}>
          <Typography variant='h5'>Location Details</Typography>
          {
            addressKeys.map(addy=> {

             return <TextField key={addy.key}
                error={!addy.optional && location[addy.key] === ''}
                required={addy.optional !== true}
                label={addy.value}
                variant={addy.readonly ? 'standard' : 'outlined'}
                disabled={addy.readonly ? true : false}
                value={location[addy.key]}
                onChange={setLocationValue(addy.key)}
              />
            })
          }

          <Button onClick={updateLocation} color='secondary' variant='outlined'>Update Location</Button>
        </Box>
      }

      { isAdmin && <Box className={classes.box} display='flex' flexDirection='column' flexGrow={1}>
          <Typography variant='h5'>Billing Information</Typography>
          <Typography variant='h6' align='left'>Invoice</Typography>
          { showPastDueAlert && <Alert severity="warning">
            <AlertTitle>Payment Issue</AlertTitle>
            We had trouble with your subscription. <strong>{billing.invoices.payment_intent.last_payment_error.message}</strong>
            <br/>Reason: <Typography variant='caption'>{billing.invoices.payment_intent.last_payment_error.decline_code}</Typography>
            <p>We will try your card again in a few days. If necessary, add a new default payment method below.</p>
          </Alert> }
          { billing.invoices && <a className={classes.anchor} href={billing.invoices.hosted_invoice_url} target='_new'>View Latest Invoice</a> }

           <Typography variant='h6' align='left'>Credit Card</Typography>
          {
            //billing.paymentMethods.map(method=><TextField key={method.id} label='Credit Card' variant='standard' disabled value={formatCard(method)}></TextField>)
            billing.paymentMethods.map(method=><p key={method.id}>{formatCard(method)}</p>)
          }
          {
            showStripe && <React.Fragment>
              <Divider/>
              <Typography variant='body2'>Add a new default credit card</Typography>
              { stripeError && <Alert severity='error'>{stripeError}</Alert> }
              <CardElement/>
              <Button onClick={()=>setProcessing(true)} variant='outlined' disabled={processing}>{processing ? 'Processing...' : 'Add'}</Button>
            </React.Fragment>
          }

          { !showStripe ? <Button color='secondary' variant='outlined' onClick={()=>setShowStripe(true)}>Add Credit Card</Button> : <Button color='secondary' variant='outlined' onClick={()=>setShowStripe(false)}>Cancel</Button>  }

        </Box>
      }

      </Box>

      </Paper>
     </Paper>
  )
}

const Settings = props => {

  return (
    <Elements stripe={stripePromise}>
      <SettingsBase {...props} />
    </Elements>
  );
};

export default Settings;