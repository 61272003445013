import React from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
}
from '@material-ui/core';

const ModalBase = props => {

  return (
    <Dialog
      fullWidth
      open={props.open}
    >
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent dividers>
        { props.children }
      </DialogContent>
      <DialogActions>
        { props.actions }
      </DialogActions>
    </Dialog>
  );
}

export default ModalBase;
