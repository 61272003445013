import React, { useState, useEffect, useMemo } from 'react';
import uuid from 'uuid';

import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";

import moment from 'moment';
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import 'react-big-calendar/lib/css/react-big-calendar.css';


const localizer = momentLocalizer(moment); // or globalizeLocalizer
const DnDCalendar = withDragAndDrop(Calendar);

const WeekView = props => {

  const [events, setEvents] = useState(props.events || []);

  const resources = useMemo(()=> {

    return props.resources.map( (resource,idx)=> {
      return {resourceId: idx+1, resourceTitle: resource }
    });

  },[props.resources]);

  useEffect(()=> {
    if(props.onEventsUpdated) {
      props.onEventsUpdated(events);
    }
  },[events]);

  const onHandleEvent = (data) => {
    const { start, end, event } = data;

    setEvents(events => {

      const idx = events.findIndex(i=>i.id === event.id);
      if(idx !== -1) {
        events[idx].start = start;
        events[idx].end = end;
      }

      return [...events];
    });
  };

  const shouldDeleteEvent = (event) => {
    setEvents(events=> {
      return [...events.filter(i=>i.id !== event.id)];
    });
  };


  const handleSelect = (data) => {

    const { start, end, resourceId } = data;

    if(start.getHours() === end.getHours() && start.getMinutes() === end.getMinutes()) {
      return;
    }

    setEvents(events => {
      return [...events, {
        id: uuid.v4(),
        start,
        end,
        resourceId,
      }];
    });
  };

  return (

     <DnDCalendar
        formats={{
          dayFormat: (date, culture, localizer) =>
            localizer.format(date, 'ddd'),
        }}
        localizer={localizer}
        events={events}
        onEventDrop={onHandleEvent}
        onEventResize={onHandleEvent}
        resources={resources}
        resizable
        selectable
        step={15}
        resourceTitleAccessor="resourceTitle"
        resourceIdAccessor="resourceId"
        onSelectSlot={handleSelect}
        onDoubleClickEvent={shouldDeleteEvent}
        dayLayoutAlgorithm={'no-overlap'}
        defaultView={Views.WEEK}
        views={{week: true}}
        startAccessor="start"
        toolbar={false}
        endAccessor="end"
        min={new Date(1980,1,1,5)}
        max={new Date(1980,1,1,22)}
        style={{ height: 800 }}
      />
  )
}

export default WeekView;