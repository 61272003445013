import React, { useState, useCallback, createContext, useEffect } from 'react';
import { SOCKET_URL } from '../Services/API/request';

let socketError = 0;
let _socket = null;

export const SocketContext = createContext();

const SocketProvider = props => {

  const { token, children } = props;

  const [socket, setSocket] = useState(null);

  const initializeSocket = useCallback(()=> {
    console.debug('init socket', _socket);

    if(_socket !== null) {
      console.debug('socket initialized');
      return;
    }

    _socket = new WebSocket(SOCKET_URL);

    if (socketError === 10) {
      console.error('Too many socket errors, waiting 10 seconds')
      //stop retrying.
      _socket.close();
      _socket = null;
      setTimeout(initializeSocket, 10000);
      _socket = 0;
      return;
    }

    _socket.addEventListener('error', event => {
      ++socketError;
      _socket.close();
    });

    _socket.addEventListener('open', event => {
      console.debug('Socket connection established');
      socketError = 0;
      var msg = {
        "type": "AUTH",
        "payload": token
      };

      try {
        _socket.send(JSON.stringify(msg));
      } catch(e) {
        setTimeout(()=> {
          _socket.send(JSON.stringify(msg));
        },500);
      }
    });

    _socket.addEventListener('close', event => {
      console.debug('socket closed');
      _socket = null;
      setImmediate(initializeSocket);
    });

    setSocket(_socket);

  },[token]);

  useEffect(()=> {
    initializeSocket();
  },[initializeSocket]);


  useEffect(()=> {
    if(_socket) {
      _socket.close();
    }


    return ()=> {
      if(_socket && _socket.close) {
        _socket.close();
        _socket = null;
      }
    };

  },[token]);

  console.debug(socket);

  return(
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );

};

export default SocketProvider;