import React, { useState } from 'react';
import { TextField, Checkbox, FormGroup, FormControlLabel, FormHelperText, Typography } from '@material-ui/core';


function getCode(accountId, url, tag, disableScheduler) {

  tag = tag ? `, 'tag', '${tag}'` : '';
  url = url === '' ? '' : `\n      ss.dataset['success'] = '${url}';`;

  const scheduler = disableScheduler ? "\n      ss.dataset['scheduler'] = 'false';" : '';


  return  `<script id='ar-js' type='text/javascript'>
    (function(a, u, t, o, r, un, f, i, tn, e, ss) {
      e && (e = [e, ss]); ss = a.createElement(t); ss.setAttribute(o, r);
      ss.dataset[un] = f; e && (ss.dataset[e[0]] = e[1]); e = a.getElementById(u).parentElement;${url}${scheduler}
      e.appendChild(ss); ss = a.createElement('script'); ss.setAttribute('src', tn); i.appendChild(ss);
    })(document, 'ar-js', 'div', 'id', 'ar-signup', 'aid', '${accountId}', document.body, 'https://s3-us-west-2.amazonaws.com/plugin.autorun.fitness/bundle.js'${tag});
  </script>`;
}


const AutomationCode = props => {

  const [url, setUrl ] = useState('');
  const [disableScheduler, setDisableScheduler] = useState(false);

  return (
    <div>
      <Typography>Copy the HTML code below and paste it your landing page.</Typography>

      <TextField
        onChange={e=>{setUrl(e.target.value)}}
        value={url}
        label='Success Page'
        placeholder='https://www.facebook.com/autorunfitness'
        helperText='Enter the page you want your leads to be taken to after the complete the signup form.'
      />
      <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox checked={disableScheduler} onChange={e=>setDisableScheduler(e.target.checked)} value={disableScheduler} />
              }
              label="Skip Scheduling Step"
            />

          </FormGroup>
          <FormHelperText>When checked, leads will only fill out their contact information, bypassing scheduling.</FormHelperText>

      <pre style={{backgroundColor: '#dedede', overflow: 'scroll', padding: '14px'}}>
        { getCode(props.accountId, url, props.tag, disableScheduler) }
      </pre>


    </div>


  );

};

export default AutomationCode;