import React, { Fragment, useEffect } from 'react';
import uuid from 'uuid';
import { makeStyles } from '@material-ui/core/styles';

import { List, ListItem, ListItemText, Divider, Grid, Typography } from '@material-ui/core';
import formatRelative from 'date-fns/formatRelative'

import ChatMessageBar from '../ChatMessageBar/ChatMessageBar';

const useStyles = makeStyles(theme => {
  return {
    margin: {
      marginRight: theme.spacing(2)
    },
    button: {
      color: theme.palette.common.white
    },
    flexLeft: {
      display: 'flex',
      justifyContent:'flex-end',
    },
    listMessageOut: {
      backgroundColor: '#2fbbfb',
      color: theme.palette.common.white,
      padding: '3px 10px',
      borderRadius: '3px',
      display: 'inline-block',
      maxWidth: '70vw'
    },
    listMessageIn: {
      marginRight: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
      padding: '3px 10px',
      borderRadius: '3px',
      display: 'inline-block',
      maxWidth: '70vw'
    },
    listMessageARF: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
      fontStyle:'italic',
      padding: '3px 10px',
      borderRadius: '3px',
      display: 'inline-block',
      maxWidth: '70vw'
    },
    messageFooter: {
      position: 'fixed',
      bottom: '56px',
      width: '100%',
      height: '56px',
      backgroundColor: theme.palette.common.white,
      padding: '0px 10px'
    }
  }
});


const IncomingMessage = props => {
  const classes = useStyles();

  return (
    <Fragment>
    <ListItem dense>
        <ListItemText
          primary={<div>{props.name} ({props.time ? formatRelative(new Date(props.time),new Date()) : 'now'})</div>}
          secondary={props.text}
          secondaryTypographyProps={{className: classes.listMessageIn, variant:'body1'}}
          />
      </ListItem>
    </Fragment>
  );
}

const OutgoingMessage = props => {
  const classes = useStyles();

  return (
    <Fragment>
      <ListItem dense style={{textAlign: 'right'}}>
        <ListItemText
          primary={<div>{props.name === 'Autorun Slack Hook' ? '🤖 AutoRun Bot' : props.name} ({props.time ? formatRelative(new Date(props.time),new Date()) : 'now'})</div>}
          secondary={props.text}
          secondaryTypographyProps={{className: (props.name === 'Autorun Slack Hook' ? classes.listMessageARF : classes.listMessageOut), variant:'body1'}}
          />

      </ListItem>

    </Fragment>
  );
}

const createMessage = (chatId, text, phone, sender) => ({
  id: uuid.v4(),
  chatId,
  phone,
  text,
  sender,
  messageType: 'message',
  timeStamp: new Date().getTime(),
  direction: 'outgoing'
});

export const MessageList = ({messages}) => {
  return <List>

          { messages.map(m=>{
            if(m.direction === 'outgoing') {
              return <OutgoingMessage key={m.id} name={m.sender} time={m.timeStamp || m.timestamp} text={m.text} />;
            }

            return <IncomingMessage key={m.id} name={m.sender} time={m.timeStamp || m.timestamp} text={m.text} />;
          })}
      </List>;
};


const Message = props => {
  const { chat, onMessageSend, user } = props;
  const { phone, id, messages } = chat;

  const classes = useStyles();

  function sendMessage(text) {

    if (!text) {
      return;
    }
    const message = createMessage(id, text, phone, user.name);
    onMessageSend(message);
  }

  useEffect(() => {
    const elm = document.getElementById('ender');
    elm.scrollIntoView(true);
  });

  return (
    <Fragment>
      <div style={{height: '56px'}}/>
       <Divider/>
        <MessageList messages={messages}/>
      <div id='ender' style={{marginBottom: '112px'}} />
      <Grid container className={classes.messageFooter} >
        <Grid item xs={12}>
          <ChatMessageBar sendMessage={sendMessage} />
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default Message;
