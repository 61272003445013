import React, { useEffect, useState, createContext } from 'react';

import Button from '@material-ui/core/Button';

import {getAccountStanding} from '../Services/API';

export const AccountStandingContext = createContext();

export const Canceled = props => {
  return (
    <div>
      <h1>Account Disabled</h1>
      <p>Your account access has been disabled due to non-payment.
      To reinstate your account, contact <a href='mailto:billing@autorun.fitness'>billing@autorun.fitness</a>
      </p>
      <Button variant='contained' color='secondary' onClick={props.onLogout}>Logout</Button>
    </div>
  );
};

const AccountStandingProvider = props => {

  const {token,children} = props;
  const [state, setState] = useState({
    standing: 'good',
    getStanding: ()=> {
      getAccountStanding(token).then(r=>{
        setState({...state,standing: r.status });
      });
    },
  });

  useEffect(()=> {
   getAccountStanding(token).then(r=>{
        setState({...state,standing: r.status });
      });
  },[token]);

  return(
    <AccountStandingContext.Provider value={state}>
      { children }
    </AccountStandingContext.Provider>
  );
};

export default AccountStandingProvider;