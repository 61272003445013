import React, { useState, createContext } from 'react';


import { Alert } from '@material-ui/lab';
export const AlertContext = createContext();

const AlertProvider = props => {

  const [state, setState] = useState({
    alertMessage: null,
    showAlert: (message)=> {
      setState({...state, alertMessage: message});
    },
  });

  return(
    <AlertContext.Provider value={state}>
      { props.children }
    </AlertContext.Provider>
  );
};

export default AlertProvider;