import React, { useState, useReducer } from 'react';

import moment from 'moment';

import {
  Stepper,
  Button,
  Step,
  StepLabel,
  StepContent,
  TextField,
  InputAdornment,
  IconButton,
  FormHelperText,
  Tabs,
  Tab,
  Paper,
  FormGroup,
  Typography,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';


import { makeStyles } from '@material-ui/core/styles';

import {
  createSchedule,
} from '../../../../Services/API';


import WeekView from './WeekView';

const useStyles = makeStyles((theme) => ({
  stepControls: {
    marginTop: theme.spacing(1)
  },
  nextButton: {
    marginLeft: theme.spacing(1)
  },
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(1)
  }
}));

const defaultNotifications = {
  texts: {
    signup: 'Hey 👋  $first! Welcome to $gym. I see you just registered for $class and I just wanted to reach out and welcome you to the gym.  💪',
    followup: 'I just wanted to make sure you were able to get on the schedule.  Did you need any help? 🧐',
    noshow: '$first looks like we missed you yesterday, so I went ahead and rescheduled you for today at the same time.  Does that work?',
    reminder: 'Hey $first just a friendly reminder of your $type tomorrow at $time. We are completely full so I just need to make sure you are still able to make it. 🤞',
    day2: 'Sorry I wasn’t able to reach you yesterday $first… when is the best time to reach 📞 you today?',
    day3: '$first did you know $class is a great way to get in shape 💪. What are you looking to accomplish by getting started?',
    day4: '$first, I wasn’t able to get a hold of you, so I went ahead and scheduled you for our next $type. LMK if you can make it! 👍',
    day5: 'Hey $first, I see you weren’t able to make it yesterday.  Will today work or would tomorrow be better?'
  },
  emails: {
    signup: {
      subject: 'Welcome $first to the gym',
      body: `$first,

I just wanted to reach out and say thanks for signing up for $class.

We hope to see you visit the gym a few times during your trial membership.

Please make sure to introduce yourself to us and reach out if you have any questions.

See You Soon!`
    },
    reminder: {
      subject: 'Appointment Reminder',
      body: `$first,

I'm super excited to see you today at $time. Just in case here is our address:

$address

Make sure to show up 15 mins before hand so we can give you a quick tour and answer any questions you may have.

Thanks`
    }
  }
};

const notificationReducer = (state, action) => {

  const ret = {...state};

  switch(action.type) {
    case 'email_body_update':
      ret.emails[action.key].body = action.value;
      break;
    case 'email_subject_update':
      ret.emails[action.key].subject = action.value;
      break;
    case 'email_reset':
      ret.emails[action.key] = defaultNotifications.emails[action.key];
      break;
    case 'text_update':
      ret.texts[action.key] = action.value;
      break;
    case 'text_reset':
      ret.texts[action.key] = defaultNotifications.texts[action.key];
      break;
  }

  return ret;
};

const NewAutomation = props => {

  const [activeStep, setActiveStep] = useState(0);
  const [tab, setTab] = useState(0);
  const [state, setState] = useState({
    name: '',
    tag: '',
    type: '',
    events: [],
    calendars: ['default'],
    showOnMaster: true,
  });

  const [notifications, dispatch] = useReducer(notificationReducer, defaultNotifications);

  const classes = useStyles();

  const changeHandler = (name,value) => {
    setState(p => ({
      ...p,
      [name]: value
    }));
  }

  const setTag = tag=> {

    tag = tag.replace(/[^\w\d_]/g,'');

    if(tag.length > 15) {
      tag = tag.substr(0,15);
    }

    setState(p=>({
      ...p,
      tag,
    }));
  }

  const onFinish = () => {

    const scheds = state.events.reduce( (red,item)=> {
      const name = state.calendars[item.resourceId-1];

      if(!red[name]) {
        red[name] = {sun:[],mon:[],tue:[],wed:[],thu:[],fri:[],sat:[]};
      }

      const start = moment(item.start);
      const end = moment(item.end);
      red[name][start.format('ddd').toLowerCase()].push(`${start.format('HH:mm')},${end.format('HH:mm')}`);

      return red;
    },{});

    createSchedule(props.token,{
      name: state.name,
      type: state.type,
      duration: state.duration || 60,
      schedules: Object.keys(scheds).map(key=>({name: key, schedule: scheds[key]})),
      closedDates: [],
      tag: state.tag,
      texts: notifications.texts,
      email: notifications.emails,
      global: state.showOnMaster
    }).then(r=> {
      props.onSuccess();
    });
  }

  const updateEvents = events => {
    setState({
      ...state,
      events,
    });
  }

  const setTabValue = (e,v) => {
    setTab(v);
  }

  return <div  style={{textAlign: 'left'}}>

    <Stepper activeStep={activeStep} orientation="vertical">
      <Step>

        <StepLabel>Details</StepLabel>
        <StepContent>
          <TextField
            fullWidth
            margin='dense'
            variant='outlined'
            value={state.name}
            label='Name'
            onChange={e=>changeHandler('name', e.target.value)}
            helperText='This name is displayed to customers when they are choosing a program. Example - "Kids Martial Arts"'
            />
            <TextField
            fullWidth
            margin='dense'
            variant='outlined'
            value={state.tag}
            label='Tag'
            onChange={e=>setTag(e.target.value)}
            helperText='When a customer signs up, they are tagged with this value.'
            />

            <TextField

            margin='dense'
            variant='outlined'
            value={state.type}
            label='Automation Type'
            select
            SelectProps={{
              native: true,
            }}
            onChange={e=>changeHandler('type',e.target.value)}
            helperText='Choose between a block of time for appointments, dedicated classes, or a private 1 on 1 session.'
            >
              <option value=''></option>
              <option value='Class'>Class</option>
              <option value='Appointment'>Appointment</option>
              <option value='Private'>Private</option>
            </TextField>

            { state.type === 'Appointment' && <TextField
            style={{width: '75px', marginLeft: '10px'}}
            margin='dense'
            variant='outlined'
            value={state.duration}
            label='Duration'
            select
            SelectProps={{
              native: true,
            }}
            onChange={e=>changeHandler('duration',e.target.value)}
            >
              <option>5</option>
              <option>10</option>
              <option>15</option>
              <option>30</option>
              <option>45</option>
              <option>60</option>
            </TextField> }


            {
              state.calendars.map( (cal,idx)=> (

                <div key={idx}>
                 <TextField
                  margin='dense'
                  variant='outlined'
                  value={cal}
                  label='Calendar Name'
                  helperText='Use this to name a calendar, such as for a specific instructor.'
                   InputProps={{
                    endAdornment:  idx !== 0 ? <InputAdornment position="end">
                      <IconButton onClick={()=>{
                       setState(s=> {
                         const ret = {...s};
                         ret.calendars.splice(idx,1);
                         return ret;
                       });

                      }}><DeleteIcon/></IconButton>

                    </InputAdornment> : undefined,
                  }}
                  onChange={e=>{
                    const val = e.target.value;
                    setState(s=> {
                      const ret = {...s};
                      ret.calendars[idx] = val;
                      return ret;
                    })
                  }}
                  />

                </div>
              ))
            }
            <Button variant='contained' onClick={()=>setState(s=> {
                  const ret = {...s};
                  s.calendars.push('New Cal');
                  return ret;
                })}>Add Calendar</Button>

             <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox checked={state.showOnMaster} onChange={e=>changeHandler('showOnMaster', e.target.checked)} value={state.showOnMaster} />
                }
                label="Publish on Master Schedule"
              />
            </FormGroup>
            <FormHelperText>Publish this schedule on your master list of schedules, or keep it hidden for use on landing pages.</FormHelperText>


          <div className={classes.stepControls}>
            <Button variant='contained' disabled onClick={()=>setActiveStep(activeStep-1)}>Back</Button>
            <Button className={classes.nextButton} variant='contained' color='secondary' onClick={()=>setActiveStep(activeStep+1)}>Next</Button>
          </div>
        </StepContent>
      </Step>
      <Step>
        <StepLabel>Schedule</StepLabel>
        <StepContent>

          <div>
            <WeekView onEventsUpdated={updateEvents} resources={state.calendars} />
          </div>

          <div className={classes.stepControls}>
            <Button variant='contained' onClick={()=>setActiveStep(activeStep-1)}>Back</Button>
            <Button className={classes.nextButton} variant='contained' color='secondary' onClick={()=>setActiveStep(activeStep+1)}>Next</Button>
          </div>
        </StepContent>
      </Step>

      <Step>
        <StepLabel>Notifications</StepLabel>
         <StepContent>
         <div>
          <Tabs
            value={tab}
            onChange={setTabValue}
          >
            <Tab label='Texts'/>
            <Tab label='Emails'/>
          </Tabs>
          { tab === 0 && <Paper square className={classes.paper}>
            {
              [{
                key: 'signup',
                value: 'Sign Up'
              },
              {
                key: 'followup',
                value: 'Follow Up'

              },
              {
                key: 'noshow',
                value: 'No Show'
              },
              {
                key: 'reminder',
                value: 'Reminder'
              },
              {
                key: 'day2',
                value: 'Day 2'
              },
              {
                key: 'day3',
                value: 'Day 3'
              },
              {
                key: 'day4',
                value: 'Day 4'
              },
              {
                key: 'day5',
                value: 'Day 5'
              }
              ].map(item=> {

                return (
                  <React.Fragment key={item.key}>
                  <Typography variant='subtitle1'>{item.value}</Typography>
                  <TextField
                    value={notifications.texts[item.key]}
                    multiline
                    variant='outlined'
                    fullWidth
                    margin='dense'
                    size='small'
                    onChange={e=>dispatch({type: 'text_update', key: item.key, value: e.target.value})}
                  />
                  <Button color='primary' onClick={()=> {
                    dispatch({type: 'text_reset', key: item.key});
                  }}>Reset</Button>
                  <Typography variant='caption' display='inline'>({notifications.texts[item.key].length})</Typography>
                  </React.Fragment>
                )
              })
            }
            </Paper>
          }
          {
            tab === 1 && <React.Fragment>
               {
              [{
                key: 'signup',
                value: 'Sign Up'
              },
              {
                key: 'reminder',
                value: 'Reminder'
              }
              ].map(item=> {

                return (
                  <Paper square className={classes.paper} key={item.key}>
                  <Typography variant='subtitle1'>{item.value}</Typography>
                  <TextField
                    value={notifications.emails[item.key].subject}
                    variant='outlined'
                    helperText='Enter the subject of your email'
                    fullWidth
                    margin='dense'
                    size='small'
                    onChange={e=>dispatch({type:'email_subject_update', key: item.key, value: e.target.value})}
                  />
                  <TextField
                    value={notifications.emails[item.key].body}
                     multiline
                    variant='outlined'
                    fullWidth
                    margin='dense'
                    size='small'
                    helperText='The body of your email'
                    onChange={e=>dispatch({type:'email_body_update', key: item.key, value: e.target.value})}
                  />

                 <Button color='primary' onClick={()=> {
                    dispatch({type: 'email_reset', key: item.key});
                  }}>Reset</Button>
                  </Paper>
                )
              })
            }
            </React.Fragment>
          }
         </div>
          <div className={classes.stepControls}>
            <Button variant='contained' onClick={()=>setActiveStep(activeStep-1)}>Back</Button>
            <Button className={classes.nextButton} variant='contained' color='secondary' onClick={onFinish}>Finish</Button>
          </div>
        </StepContent>
      </Step>
    </Stepper>
  </div>


};

export default NewAutomation;