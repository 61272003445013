/* global localStorage */
/* global Notification */
import React, { Component, useEffect, useCallback } from 'react';

import Inbox from './Apps/Inbox/Inbox';
import Appointments from './Apps/Appointments/Appointments';
import Contacts from './Apps/Contacts/Contacts';

//import { ContactsContext, ContactsProvider } from '../../Context/ContactsContext';

import { Box, BottomNavigation, BottomNavigationAction, Grid } from '@material-ui/core';

import InboxIcon from '@material-ui/icons/Inbox';
import EventNoteIcon from '@material-ui/icons/EventNote';
import PeopleIcon from '@material-ui/icons/People';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';


import PrimarySearchAppBar from '../../Components/AppBar/AppBar';

import {
  createChat as createChatApi,
  getAccounts as getAccountsApi,
  getUser as getUserApi,
  getTokenForAccount as getTokenForAccountApi,
  getChats as getChatsApi,
  getChatData as getChatDataApi,
  getContact as getContactApi,
  getAllContacts as getAllContactsApi,
  setChatStatus as setChatStatusApi,
  registerDevice as registerDeviceApi,
}
from '../../Services/API';

import { SOCKET_URL } from '../../Services/API/request';

import AccountChooser from './Dialogs/AccountChooser/AccountChooser';
import EditContact from './Dialogs/EditContact/EditContact';
import CreateContact from './Dialogs/CreateContact/CreateContact';
import NewMessage from './Dialogs/NewMessage/NewMessage';
import BookAppointment from './Dialogs/BookAppointment/BookAppointment';
import Menu from './Menu/Menu';
import DialogBase from '../../Components/Dialogs/DialogBase';

import Contact from '../../Components/Contact/Contact';

const CREATE_CONTACT_ACTION = 'CREATE_CONTACT_ACTION';
const BOOK_APPOINTMENT_ACTION = 'BOOK_APPOINTMENT_ACTION';
const NEW_MESSAGE_ACTION = 'NEW_MESSAGE_ACTION';
const SWICH_ACTION = 'SWITCH_ACCOUNT';
const EDIT_CONTACT_ACTION = 'EDIT_CONTACT_ACTION';
const STATE_VARIABLE = '_main_state';

function saveStateLocal(state) {
  localStorage.setItem(STATE_VARIABLE, JSON.stringify(state));
}

function clearState() {
  localStorage.removeItem(STATE_VARIABLE);
}

function loadState() {
  let state = localStorage.getItem(STATE_VARIABLE);
  if (!state) {
    return;
  }
  try {
    state = JSON.parse(state);
    return state;
  }
  catch (e) {
    console.error(e);
  }
}

let socketError = 0;
let socket = null;

const WebSocketProvider = props => {

  const { token, onMessage } = props;

   const initializeSocket = useCallback(()=> {
    console.log('init socket', socket);

    if(socket !== null) {
      console.log('socket initialized');
      return;
    }

    socket = new WebSocket(SOCKET_URL);

    if (socketError === 10) {
      console.log('Too many socket errors, waiting 10 seconds')
      //stop retrying.
      socket.close();
      socket = null;
      setTimeout(initializeSocket, 10000);
      socketError = 0;
      return;
    }

    socket.addEventListener('error', event => {
      ++socketError;
      socket.close();
    });

    socket.addEventListener('open', event => {
      console.log('Socket connection established');
      socketError = 0;
      var msg = {
        "type": "AUTH",
        "payload": token
      };

      //todo: verify auth was successful
      socket.send(JSON.stringify(msg));
    });

    socket.addEventListener('close', event => {
      console.log('socket closed');
      socket = null;
      setImmediate(initializeSocket);
    });

    socket.addEventListener('message', event => {
      onMessage(event.data);
    });
  },[token, onMessage]);

  useEffect(() => {
    initializeSocket();
  }, [initializeSocket]);

  return props.children;
};

class Main extends Component {

  constructor(props) {
    super(props);
    this.state = {
      accounts: [],
      user: null,
      action: '',
      accountToken: '',
      showMenu: false,
      showContact: false,
      selectedContact: null,
      contacts: [],
      chats: [],
      chatData: null,
      selectedApp: 0,
    }
  }

  refreshAllChats = () => {
    getChatsApi(this.state.accountToken).then(r => {
      this.setState({ chats: r });
    });
  }

  refreshAllContacts = () => {
    getAllContactsApi(this.state.accountToken).then(r => {
      this.setState({contacts: r.customers});
    });
  }

  saveState = () => {
    saveStateLocal({
      accountToken: this.state.accountToken,
      account: this.state.account,
      user: this.state.user,
      accounts: this.state.accounts,
      selectedApp: this.state.selectedApp,
      selectedContact: this.state.selectedContact,
      showContact: this.state.showContact,
    });
  }

  componentDidMount() {

    const storedState = loadState();

    if (storedState) {
      this.setState({
        ...storedState
      }, ()=> {this.refreshAllChats(); this.refreshAllContacts()});
      return;
    }

    Promise.all([
      getUserApi(this.props.token),
      getAccountsApi(this.props.token),
    ]).then(res => {
      const [userResponse, accountsResponse] = res;
      if (userResponse.status !== 'ok' ||
        accountsResponse.status !== 'ok') {
        return this.props.onLogout();
      }

      const accountData = {
        accountToken: this.props.token,
        account: userResponse.user.account,
        user: userResponse.user,
        accounts: accountsResponse.accounts
      };

      this.setState({
        ...accountData
      }, () => {
        this.saveState();
        this.refreshAllChats();
        this.refreshAllContacts();
      });
    });
  }

  notifyUser = (message) => {

  const text = `Autorun.Fitness - ${message.sender}: ${message.text}`;

  if (!("Notification" in window)) {
    console.log('No notification support.');
    return;
  }

  else if (Notification.permission === "granted") {
    new Notification(text);
  }

  else {
    Notification.requestPermission(function(permission) {
      // If the user accepts, let's create a notification
      if (permission === "granted") {
        console.log('sending notification', text);
        new Notification(text);
      }
      else {
        console.log('notification not granted');
      }
    });
  }
};

  handleSocketMessage = message => {
    message = JSON.parse(message);
    const { chats, selectedContact } = this.state;

    if(message.type === 'CHAT_UPDATE') {
      const idx = chats.findIndex(x=>x.id === message.payload.id);
      if(idx === -1) {
        message.payload.messages = [];
        this.setState({chats: [message.payload, ...chats]});
      } else {
        chats[idx].status = message.payload.status;
        chats[idx].lastUpdate = message.payload.lastUpdate;
        this.setState({chats});
      }
      return;
    }

    if (message.type !== 'CHAT_MESSAGE') {
      return;
    }

    const chatId = message.payload.chatId;

    const idx = chats.findIndex(x => x.id === chatId);

    if(message.payload.timestamp) {
      //hack
      message.payload.timeStamp = message.payload.timestamp;
    }

    if (idx !== -1) {
      if(message.payload.direction === 'incoming') {
        chats[idx].status = 'NEW';
      }
      chats[idx].messages = [message.payload];
    }

    if (selectedContact && selectedContact.id === chatId) {
      if(message.payload.direction === 'incoming') {
        selectedContact.chat.status = 'NEW';
      }
      selectedContact.chat.messages.push(message.payload)
    }

    if(message.payload.direction === 'incoming') {
      this.notifyUser(message.payload);
    }

    this.setState({ chats, selectedContact },this.saveState);
  }

  handleMenuClick = (name) => () => {
    switch (name) {
      case 'SWITCH':
        this.setState({ action: SWICH_ACTION });
        break;
      case 'LOGOUT':
        clearState();
        this.props.onLogout();
        break;
      default:
        break;
    }
  };

  handleNewAccountSelected = accountId => {
    getTokenForAccountApi(this.props.token, accountId).then(result => {

      if (result.status !== 'ok') {
        return this.props.onLogout();
      }

      this.setState({
        user: result.user,
        account: result.user.account,
        accountToken: result.token,
        action: ''
      }, () => {
        this.saveState();
        this.refreshAllChats();
        this.refreshAllContacts();
      });
    });
  };

  menuItems = [
    { name: "Switch Accounts", click: this.handleMenuClick('SWITCH'), icon: <SwapHorizIcon/> },
    { name: "Logout", click: this.handleMenuClick('LOGOUT'), icon: <ExitToAppIcon/> },
  ];

  closeActionDialog = () => this.setState({ action: '' });

  showContact = async id => {
    if (!id) {
      return;
    }

    const p1 = getChatDataApi(this.state.accountToken, id);
    const p2 = getContactApi(this.state.accountToken, id);
    const p3 = setChatStatusApi(this.state.accountToken, id, 'OPEN');

    Promise.all([p1, p2, p3]).then(async results => {
      let [chat, contact] = results;

      if(chat.status ==='Chat not found') {
        chat = await createChatApi(this.state.accountToken, contact.customer.name, contact.customer.phone, contact.customer.id);
        this.refreshAllChats();
        alert('Please try again in a moment');
        return;
      }

      const idx = this.state.chats.findIndex(c=>c.id === id);
      if(idx !== -1) {
        this.state.chats[idx].status = 'OPEN';
      }

      this.setState({
        showContact: true,
        action: '',
        chats: this.state.chats,
        selectedContact: { ...contact.customer, chat }
      },this.saveState);
    });
  };

  render() {

    const { showMenu, chats, contacts, showContact, selectedContact,
        accounts, account, accountToken, action, user, selectedApp } = this.state;

    if (!user) {
      return null;
    }

    let app = '';
    let inbox = <Inbox
                  chats={chats}
                  user={user}
                  newMessage={()=>this.setState({action:NEW_MESSAGE_ACTION})}
                  onMessageClick={this.showContact}/>;

    switch (selectedApp) {
      case 0:
        app = inbox;
        break;
      case 1:
        app = <Appointments token={accountToken} user={user} onSchedule={()=>this.setState({action: BOOK_APPOINTMENT_ACTION})}/>;
        break;
      case 2:
        app = <Contacts contacts={contacts} user={user} onContactClick={this.showContact} onNewContact={()=>this.setState({action: CREATE_CONTACT_ACTION})}/>;
        break;
      default:
        app = inbox;
      //this.saveState();
    }

    return (
      <WebSocketProvider token={accountToken} onMessage={this.handleSocketMessage}>
        <PrimarySearchAppBar register={(deviceToken)=>registerDeviceApi(accountToken,deviceToken)} title={account.company} onClick={()=>{this.setState({showMenu: !showMenu})}}/>
        <Menu title={account.company} name={user.name} menuItems={this.menuItems} open={showMenu} close={()=>this.setState({showMenu: false})}/>

        <DialogBase title="Choose Account" open={action === SWICH_ACTION} handleClose={this.closeActionDialog}>
          <AccountChooser accounts={accounts} accountSelected={this.handleNewAccountSelected}/>
        </DialogBase>

        <DialogBase title="Create Contact" open={action === CREATE_CONTACT_ACTION} handleClose={this.closeActionDialog} disabled={this.state.saveContact} actionButton onAction={()=>this.setState({saveContact:true}, this.refreshAllContacts)}>
          <CreateContact user={user} token={accountToken}
            handleSendMessage={selectedContact=>this.showContact(selectedContact.id)} save={this.state.saveContact} onSaved={()=>this.setState({saveContact:false})}/>
        </DialogBase>

        <DialogBase title="New Message" open={action === NEW_MESSAGE_ACTION} handleClose={this.closeActionDialog} >
          <NewMessage user={user} token={accountToken} onMessageSent={cid=>this.showContact(cid)} />
        </DialogBase>

        <DialogBase title="Edit Contact" open={action === EDIT_CONTACT_ACTION} handleClose={this.closeActionDialog}>
          <EditContact contact={selectedContact} token={accountToken} handleClose={this.closeActionDialog}/>
        </DialogBase>

        <DialogBase title="Book Appointment" open={action === BOOK_APPOINTMENT_ACTION} handleClose={this.closeActionDialog}>
          <BookAppointment user={user} contact={selectedContact} token={accountToken} handleClose={this.closeActionDialog}/>
        </DialogBase>

        <div style={{height: '56px'}}/>
        <Box display={showContact ? 'none' : null}>
          { app }
        </Box>

        <Box zIndex='modal' top='0px'>
          { showContact ?
          <Contact user={user}
            token={accountToken}
            contact={selectedContact}
            onBack={()=>this.setState({showContact: false},this.saveState)}
            onEdit={()=>this.setState({action: EDIT_CONTACT_ACTION})}
            onSchedule={()=>this.setState({action: BOOK_APPOINTMENT_ACTION})}
            />: null}
        </Box>

            {

            // <Grid container>
            //   <Grid item xs={12} md={4}>
            //     {  app }
            // </Grid>
            // <Grid item xs={12} md={8}>
            // { showContact ?
            //   <Contact user={user}
            //     token={accountToken}
            //     contact={selectedContact}
            //     onBack={()=>this.setState({showContact: false},this.saveState)}
            //     onEdit={()=>this.setState({action: EDIT_CONTACT_ACTION})}
            //     onSchedule={()=>this.setState({action: BOOK_APPOINTMENT_ACTION})}
            //     />: null}
            // </Grid>
            // </Grid>

            }

        <BottomNavigation style={{position:'fixed',bottom:0, width: '100%', height: '56px'}} value={selectedApp} showLabels onChange={(e,v)=>{this.setState({selectedApp: v, selectedContact: null, showContact: false},this.saveState)}}>
          <BottomNavigationAction label="Messages" icon={<InboxIcon/>} />
          <BottomNavigationAction label="Appointments" icon={<EventNoteIcon/>} />
          <BottomNavigationAction label="Leads" icon={<PeopleIcon/>} />
        </BottomNavigation>
      </WebSocketProvider>
    );
  }
}

export default Main;
