import React from 'react';

import { FormControl, OutlinedInput , InputLabel, InputAdornment,IconButton } from '@material-ui/core';

import ClearIcon from '@material-ui/icons/Clear';

const PhoneInput = props => {

  const {value, onChange, clear, onBlur} = props;

  return (
     <FormControl variant='outlined' className={props.classes.textField}>
      <InputLabel style={{backgroundColor:'#FFF'}} variant='outlined' shrink htmlFor="text-mask-input">Cell Phone</InputLabel>
      <OutlinedInput
        required
        fullWidth
        inputProps={props.inputProps}
        id='text-mask-input'
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={clear}>
                  <ClearIcon/>
                </IconButton>
              </InputAdornment>
            }
      />
    </FormControl>
  );
};

export default PhoneInput;
