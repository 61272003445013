import React, {useState, useMemo, useEffect, useContext} from 'react';

import {
  useLocation,
} from "react-router-dom";

import {
  getChats,
  getContact,
  setChatStatus as setChatStatusApi,
} from '../../../../Services/API';

import { Paper, Button } from '@material-ui/core';

import {ChatListLarge, getInitials} from './ChatList';
import Contact from '../../../../Components/Contact/ContactLarge';
import AppHeader from '../../../../Components/AppHeader/AppHeaderLarge';
import ModalBase from '../../../../Components/Dialogs/ModalBase';
import NewMessage from '../../Dialogs/NewMessage/NewMessage';

import { SocketContext } from '../../../../Context/SocketContext';

const AutoUpdateChatList = props => {

  const { chats } = props;

    const [ filteredChats, setFilteredChats] = useState([]);

    useEffect(()=> {
      setFilteredChats(chats);
    }, [chats]);

    const socket = useContext(SocketContext);

    const updateChatList = (event) => {

      try {
        let socketMsg = JSON.parse(event.data);

        if(socketMsg.type === 'CHAT_MESSAGE') {

          setFilteredChats(_chats=> {

            const data = [..._chats];

            const idx = data.findIndex(c=>c.id === socketMsg.payload.chatId);
            const chat = data[idx];

            if(chat) {
              chat.messages = [socketMsg.payload];
              if(socketMsg.payload.direction === 'incoming') {
                chat.status = 'NEW';
              }
              data[idx] = chat;
            }

            return data;
          });
        }
      } catch(e) {
        //eat
      }
    };

    useEffect(()=> {

      if(!socket) {
        return;
      }
      socket.addEventListener('message', updateChatList);

      return ()=> {
        socket.removeEventListener('message', updateChatList);
      };

    },[socket]);

    return <ChatListLarge onNew={props.onNew} title={props.title} chats={filteredChats} onSelected={props.onSelected}/>;
};

const Inbox = ({token, user}) => {

  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [contactForChat, setContactForChat] = useState(null);
  const [showNewMessage, setShowNewMessage] = useState(false);

  const location = useLocation();

  const filteredChats = useMemo(()=>{
    if(location.hash === '#unread') {
      return chats.filter(c=>c.status === 'NEW');
    } else {
      return chats;
    }
  },
  [location.hash,chats]);

  useEffect(()=> {

    setSelectedChat(null);
    setContactForChat(null);

    getChats(token).then(r=> {
      setChats(r);
      if(r.length > 0) {
        getContactForChat(r[0]);
      }
    });
  }, [token]);

  const refreshChat = () => {
    getChats(token).then(r=> {
      setChats(r);
    });
  };

  const getContactForChat = async chat => {

    if(chat.status === 'NEW') {
      await setChatStatusApi(token, chat.id, 'OPEN')
    }

    const contact = await getContact(token, chat.id);
    setSelectedChat(chat);
    setContactForChat(contact.customer);
    refreshChat();
 };


  return (
    <Paper style={{margin: '10px', padding: '10px'}}>

      <ModalBase title='New Message' open={showNewMessage} actions={<Button onClick={()=>setShowNewMessage(false)}>Close</Button>}>
        <NewMessage token={token} user={user} onMessageSent={refreshChat}/>
      </ModalBase>
       {
        //<AppHeader label='New message' title={'Messages'}/>

        //<Divider style={{margin: '20px 10px'}}/>
       }
      <div style={{display: 'flex'}}>
        <div style={{flexGrow: 0}}>
          <AutoUpdateChatList  onNew={()=>{setShowNewMessage(true)}} title={location.hash === '#unread' ? 'Unread Messages' : 'All Messages'} chats={filteredChats} onSelected={chat=>{getContactForChat(chat)}}/>
        </div>
        <div style={{flexGrow: 1}}>
          { contactForChat && <Contact token={token} initials={getInitials(contactForChat.name)} chatId={selectedChat.id} contact={contactForChat}/> }
        </div>
      </div>
    </Paper>
  );
};

export default Inbox;