import React, { Fragment, useEffect, useState } from 'react';

import moment from 'moment';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  DatePicker,
}
from '@material-ui/pickers';

import { makeStyles } from '@material-ui/core/styles';

import {
  Button,
  Grid,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  Select,
}
from '@material-ui/core';


import AppHeader, {AppHeaderButton} from '../../../../Components/AppHeader/AppHeader';
import ModalBase from '../../../../Components/Dialogs/ModalBase';

import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import AddIcon from '@material-ui/icons/Add';
import TodayIcon from '@material-ui/icons/Today';

import { getAppointmentForDate as getAppointmentForDateApi, updateAppointment as updateAppointmentApi } from '../../../../Services/API';

const useStyles = makeStyles(theme => ({
  margin: {
    marginRight: theme.spacing(2)
  },
  button: {
    color: theme.palette.common.white
  },
  content: {
    textAlign: 'left',
    paddingLeft: theme.spacing(2)
  }
}));


const AppointmentActions = props => (
  <span>
    <AppHeaderButton onClick={props.onSchedule}><AddIcon/></AppHeaderButton>
    <AppHeaderButton onClick={props.onFilter}><TodayIcon/></AppHeaderButton>
  </span>
);


const Appointment = props => {

  return (
    <Fragment>
  <ListItem>
    <ListItemText
      primary={props.name}
      secondary={
        <Fragment>
          <span>{props.time} {props.status.replace(/_/, ' ')}</span>
        </Fragment>
      }
    />
   <ListItemSecondaryAction>
      <Select
        variant='outlined'
        onChange={e=>props.setStatus(props.appointmentId, e.target.value)}
        value={props.status}
      >
        <MenuItem value='CONFIRMED'>Confirm</MenuItem>
        <MenuItem value='ENROLLED'>Enroll</MenuItem>
        <MenuItem value='SHOW'>Show</MenuItem>
        <MenuItem value='NO_SHOW'>No Show</MenuItem>
        <MenuItem value='CANCELED'>Cancel</MenuItem>
      </Select>

     </ListItemSecondaryAction>
  </ListItem>
  <Divider component="li" />
  </Fragment>)
}


const Appointments = props => {

  const { token } = props;

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [appointments, setAppointments] = useState([]);
  const [open, setOpen] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    selectedDate.setHours(0,0,0,0);
    getAppointmentForDateApi(token, selectedDate.getTime()).then(r => {
      if (r.status === 'ok') {
        setAppointments(r.appointments);
      }
    });

  }, [token,selectedDate]);

  const getAppointments = () => {
    selectedDate.setHours(0,0,0,0);
    getAppointmentForDateApi(token, selectedDate.getTime()).then(r => {
      if (r.status === 'ok') {
        setAppointments(r.appointments);
      }
    });
  }

  const setStatus = (id,status) => {
    updateAppointmentApi(token,id,status).then(r=> {
      if(r.status !== 'ok') {
        return;
      }

      const idx = appointments.findIndex(i=>i.id===id);
      if(idx !== -1) {
        appointments[idx].status = r.appointmentStatus;
        setAppointments([...appointments]);
      }
    });
  }

  return (
      <Fragment>
        <AppHeader title='Appointments'
          secondaryAction=<AppointmentActions onSchedule={props.onSchedule} onFilter={()=>setOpen(true)}/>
        />
        <div style={{height: '56px'}}/>
        <ModalBase
          title='Choose a Date'
          open={open}
          actions={<Fragment>
              <Button variant='text' onClick={()=>{setSelectedDate(new Date()); setOpen(false)}}>Today</Button>
              <Button onClick={()=>setOpen(false)} color='primary'>Close</Button>
            </Fragment>
          }>

            <Typography>Custom</Typography>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        disableToolbar
                        variant="static"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="Showing Appointments For"
                        value={selectedDate}
                        onClose={getAppointments}
                        onChange={(e)=>setSelectedDate(e)}/>
            </MuiPickersUtilsProvider>
        </ModalBase>
        <Grid container direction='row' justify='center' alignItems='center'>
          <Grid item>
            <IconButton size='medium' onClick={()=>setSelectedDate(moment(selectedDate).subtract(1,'days').toDate())}><ArrowLeftIcon/></IconButton>
           </Grid>
           <Grid item>
              <Typography>{moment(selectedDate).format('LL')}</Typography>
          </Grid>
          <Grid item>
            <IconButton size='medium' onClick={()=>setSelectedDate(moment(selectedDate).add(1,'days').toDate())}><ArrowRightIcon/></IconButton>
          </Grid>
        </Grid>
        <div className={classes.content}>
        { appointments.length === 0 ? <Typography variant='h6'>No Appointments Scheduled</Typography> : null }
        <List dense> { appointments.map(a =>
          <Appointment
            key={a.id}
            appointmentId={a.id}
            time={moment(a.date).format('LT')}
            name={a.name}
            setStatus={setStatus}
            status={a.status}/>) }
        </List>
        </div>
  </Fragment>
);


};

export default Appointments;
