/* global Notification */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';

import Logo from '../../logo.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    height: '56px'
  },
  logo: {
    height: '50px',
  },
  title: {
    padding: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: theme.palette.white,
  },
}));

export default function ButtonAppBar(props) {
  const classes = useStyles();
  const [anchor, setAnchor] = React.useState(null);

  const handleMenu = event => {
    setAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setAnchor(null);
  };

  const menuHandler = handler => () => {
    handleClose();
    handler();
  }




  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <img className={classes.logo} src={Logo} />
          <div className={classes.spacer}/>
          <Typography variant='subtitle1'>Welcome,️ {props.name}</Typography>
          <IconButton edge='end' className={classes.menuButton} onClick={handleMenu}>
            <MenuIcon/>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Menu
        id="simple-menu"
        anchorEl={anchor}
        keepMounted
        open={Boolean(anchor)}
        onClose={handleClose}
      >
      <Typography className={classes.title} variant='subtitle1' color='secondary'>{props.accountName}</Typography>
      <Divider/>
      {
        props.menuItems.map(item=>
          <MenuItem key={item.name} onClick={menuHandler(item.handler)}>{item.name}</MenuItem>
        )

      }
        <Divider/>
        <MenuItem><Link target="_blank" href='https://legacyapp.autorun.fitness/'>Autorun V1</Link></MenuItem>
      </Menu>
    </div>
  );
}