/* global Notification */
import React, { useEffect, useContext } from 'react';

import { SocketContext } from '../../Context/SocketContext';

const BrowserNotification = () => {

  const socket = useContext(SocketContext);

  const notifyUser = (event) => {

    let message = '';

    try {
      let socketMsg = JSON.parse(event.data);

      if(socketMsg.type === 'CHAT_MESSAGE') {

        message = socketMsg.payload;

      } else {
        return;
      }
    } catch(e) {
      return;
    }

    const text = `Autorun.Fitness - ${message.sender}: ${message.text}`;

    if (!("Notification" in window)) {
      console.log('No notification support.');
      return;
    }

    else if (Notification.permission === "granted") {
      new Notification(text);
    }

    else {
      Notification.requestPermission(function(permission) {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          console.log('sending notification', text);
          new Notification(text);
        }
        else {
          console.log('notification not granted');
        }
      });
    }
  };

  useEffect(()=> {

    if(!socket) {
      return;
    }

    socket.addEventListener('message', notifyUser);

    return ()=> {
      socket.removeEventListener('message', notifyUser);
    };

  },[socket]);


  return null;
};

export default BrowserNotification;