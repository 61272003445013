import React, { Fragment } from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';


const SimpleListItem = props => (
  <Fragment>
    <ListItem button onClick={props.onClick}>
      <ListItemText primary={props.text}/>
    </ListItem>
    <Divider/>
  </Fragment>
);


const AccountChooser = props => {
  return (
    <List>
      { props.accounts.sort((a,b)=> {
        if(a.company > b.company) {
          return 1;
        } else if (a.company < b.company) {
          return -1;
        } else {
          return 0;
        }
      }).map( account => <SimpleListItem key={account.accountId} text={account.company} onClick={()=>props.accountSelected(account.accountId)}/> )}
    </List>
  );
};

export default AccountChooser;
