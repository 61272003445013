import React, { useState } from 'react';

import { Box, List, ListItem, ListItemText, Typography, NativeSelect } from '@material-ui/core';

import AppHeader, {AppHeaderButton} from '../../../../Components/AppHeader/AppHeader';

import AddIcon from '@material-ui/icons/Add';

const letters = 'abcdefghijklmnopqrstuvwxyz#'.split('');

const ContactListItem = props => (
  <ListItem button onClick={props.onClick}>
    <ListItemText>{props.name}</ListItemText>
  </ListItem>
);

const Contacts = props => {

  const [filter, setFilter] = useState('a');

  const { onContactClick, contacts } = props;

  const data = contacts[filter] || [];

  return (
    <div style={{height: '100%', paddingBottom: '66px'}}>
      <AppHeader title='Contacts'
        secondaryAction={<AppHeaderButton onClick={props.onNewContact}><AddIcon/></AppHeaderButton>}

      />
      <div style={{height: '56px'}}/>
      <div>
         <Box position='sticky' width='100%'>
           <NativeSelect
            fullWidth
            variant='outlined'
            onChange={e=>setFilter(e.target.value)}
           >

            { letters.map(l=><option key={l} value={l.toLowerCase()}>{l.toUpperCase()}</option>) }
           </NativeSelect>
         </Box>
         <Box mt={2} overflow='auto' flexGrow={1}>
            { data.length === 0 ?

            <Typography>No Contacts</Typography> :

            <List>
              { data.map(contact => <ContactListItem key={contact.id} onClick={()=>onContactClick(contact.id)} name={contact.rawName}/>)}
            </List> }
          </Box>
      </div>
    </div>
  )

};

export default Contacts;
