import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { Box, IconButton, Typography } from '@material-ui/core';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles(theme => {
  return {

    appHeader: {
      padding: '10px 0px',
      position: 'fixed',
      top: '56px',
      backgroundColor: theme.palette.primary.light,
      width: '100%',
      color: theme.palette.common.white
    },
    icons: {
      position: 'absolute',
      right: '10px',
      top: '13px'
    },
    backButton: {
      position: 'absolute',
      left: '10px',
      top: '13px',
      color: theme.palette.common.white
    }
  };
});

export const AppHeaderButton = props => (
  <IconButton onClick={props.onClick} size='small' style={{color: 'white'}}>{props.children}</IconButton>
)

const AppHeader = props => {

  const classes = useStyles();


  return (
    <Box zIndex="modal" className={classes.appHeader}>
      <Typography variant='h5'>{props.title}</Typography>
      {props.onBack ? <IconButton size='small' className={classes.backButton} onClick={props.onBack}><ArrowBackIcon/></IconButton> : null }
      <div className={classes.icons}>
        {props.secondaryAction}
      </div>
  </Box>);
};

export default AppHeader;
