import React, { Fragment, useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import {
  Avatar,
  Badge,
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Fab

}
from '@material-ui/core';

import ChevronRightSharpIcon from '@material-ui/icons/ChevronRightSharp';
import FilterListIcon from '@material-ui/icons/FilterList';
import AddIcon from '@material-ui/icons/Add';

import AppHeader, { AppHeaderButton } from '../../../../Components/AppHeader/AppHeader';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '80vh',
    flexDirection: 'column',
    width: '340px',
  },
  content: {
    flexGrow: 1,
    overflowY: 'scroll',
    height: '0px',
  },
  spacer: {
    flexShrink: 0,
  },
  margin: {
    marginRight: theme.spacing(2)
  },
  dot: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2)
  }
}));


export const getInitials = name => {

  if(!name) {
    return '';
  }

  const arr = name.split(' ');
  if (arr.length < 2) {
    return name.charAt(0);
  }

  return `${arr[0].charAt(0)}${arr[1].charAt(0)}`.toUpperCase();
};


const ChatListItem = props => {

  const classes = useStyles();

  return (
    <Fragment>
   <ListItem button alignItems="flex-start" onClick={()=>{props.handleClick()}}>
      <Badge
        color={props.status === 'NEW' ? 'error': ''}
        variant='dot'
        badgeContent={1}
        className={classes.dot}/>
      { props.avatar &&
      <Avatar color='secondary' className={classes.margin}>{getInitials(props.name)}</Avatar> }
      <ListItemText
        primary={props.name}
        secondary={props.message}
        secondaryTypographyProps={{noWrap: true}}/>
      <ListItemIcon >
        <ChevronRightSharpIcon style={{marginLeft: '50px', marginTop: '10px'}} />
      </ListItemIcon>
    </ListItem>
    <Divider/>
  </Fragment>
  );
};

const InboxActions = props => (
  <span>
    <AppHeaderButton onClick={props.onAdd}><AddIcon/></AppHeaderButton>
    <AppHeaderButton onClick={props.onClick}><FilterListIcon/></AppHeaderButton>
  </span>
);

const filteredMessages = (filters, messages) => {
  if (filters === 'ALL') {
    return messages;
  }

  return messages.filter(a => a.status === 'NEW');
};

function sortMessage(a, b) {
    if(a.messages.length === 0) {
      return -1;
    }
    if(b.messages.length === 0) {
      return 1;
    }

    if(a.messages[0].timeStamp > b.messages[0].timeStamp ) {
      return -1;
    }

    if(a.messages[0].timeStamp < b.messages[0].timeStamp) {
      return 1;
    }

    return 0;
}

export const ChatListLarge = props => {

  const classes = useStyles();


  return (
    <React.Fragment>
    <div style={{textAlign: 'left', margin: '10px'}}>
     <Fab variant="extended" onClick={props.onNew} style={{backgroundColor: 'white'}}>
        <AddIcon color='secondary'/>
        New Message
      </Fab>
    </div>
    <div className={classes.root}>

      <Typography style={{flexShrink: 0}}>{props.title}</Typography>

      <div className={classes.content}>

        {

        props.chats.length > 0 ?

        <List dense disablePadding>
          {
            props.chats.map(chat => {

              const msg = chat.messages[0];
              return <ChatListItem key={chat.id} handleClick={()=>props.onSelected(chat)} name={chat.name} direction={msg ? msg.direction : '' } message={msg  ? msg.text : '[downloading]'} status={chat.status} />
              }
            )
          }
        </List> : <Typography>No Unread Messages</Typography>
        }
      </div>
      <div className={classes.spacer}></div>
    </div>
    </React.Fragment>
  );
}


const ChatList = props => {

  const [filters, setFilters] = useState('UNREAD');

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div style={{paddingBottom: '66px'}}>
      <AppHeader title='Inbox'
        secondaryAction={<InboxActions onAdd={props.onAdd} onClick={()=>{setFilters(filters === 'ALL' ? 'UNREAD' : 'ALL')}}/>}
      />
      <div style={{marginTop: '56px'}}>
        <Typography gutterBottom variant='h5'>{filters === 'UNREAD' ? 'Unread Messages' : 'Last 100 Messages'}</Typography>
        <List dense disablePadding>
          { filteredMessages(filters,props.messages).sort(sortMessage).map(message=>
            <ChatListItem key={message.id} avatar handleClick={()=>{props.onChatClick(message.id)}} name={message.name} message={ message.messages.length === 0 ? '[downloading...]' : message.messages[0].text} status={message.status}/>)}
        </List>
      </div>
    </div>
  );
};


export default ChatList;
