/* global URLSearchParams */

import React, {
  useState,
  useEffect
}
from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { login as loginApi, resetPassword as resetPasswordApi, setNewPassword as setNewPasswordApi } from '../../Services/API';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
        Autorun Fitness LLC&nbsp;
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const ForgotPasswordForm = props => {

  const [email, setEmail] = useState('');

  const { classes, resetPassword, onCancel } = props;

  return (
  <React.Fragment>
          <Typography component='h1' variant='h5'>
            Reset your password
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={e=>setEmail(e.target.value)}
            />
           <Button
            onClick={()=>resetPassword(email)}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Reset
          </Button>
            <Button
            onClick={onCancel}
            className={classes.submit}
          >
            Cancel
          </Button>
          </form>

        </React.Fragment>
  );
}

const PasswordResetForm = props => {

  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [temp, setTemp] = useState('');

  const { classes, setNewPassword, onCancel } = props;

  const handleUpdate = () => {
    if(password1 !== password2) {
      return alert('Passwords don\'t match.');
    }

    if(!temp || !password1) {
      return alert('All fields are required');
    }

    setNewPassword(temp, password1);
  }

  return (
  <React.Fragment>
          <Typography component='h1' variant='h5'>
            Reset your password
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="temp"
              label="Temp Password"
              name="temp"
              autoComplete="temp"
              autoFocus
              type="password"
              value={temp}
              onChange={e=>setTemp(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="password1"
              label="New Password"
              name="password1"
              autoComplete="password1"
              type="password"
              value={password1}
              onChange={e=>setPassword1(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="temp"
              label="New Password (again)"
              name="password2"
              autoComplete="password2"
              type="password"
              value={password2}
              onChange={e=>setPassword2(e.target.value)}
            />
           <Button
            onClick={()=>handleUpdate()}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Set New Password
          </Button>
            <Button
            onClick={onCancel}
            className={classes.submit}
          >
            Cancel
          </Button>
          </form>
        </React.Fragment>
  );
}


export default function Login(props) {
  const classes = useStyles();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [forgotPassword, setForgotPassword] = useState(false);
  const [showPasswordReset, setShowPasswordReset] = useState(false);
  const [token, setToken] = useState('');

  useEffect(()=> {
    if(document.location.href.indexOf('/passwordReset') !== -1) {
      setToken(new URLSearchParams(document.location.search).get('token'));
      setShowPasswordReset(true);
      window.history.pushState('Login', 'Title', '/');
    }
  },[]);

  const authenticate = (email, password) => {

    if (!email || !password) {
      setError(true);
      setErrorText('Email and Password are required');
      return;
    }

    loginApi(email, password).then(response => {
      if (response.status !== 'ok') {
        setError(true);
        setErrorText('Login failed. Check email and password.');
        return;
      }

      props.onSuccess(response.token);
    });
  }

  const resetPassword = email => {
    if(!email) {
      setError(true);
      setErrorText('Email is required');
    }

    resetPasswordApi(email).then(response => {
      alert('Check your email for the password reset instructions.');
      setForgotPassword(false);
    });
  }

  const setNewPassword = (temp, password) => {
    setNewPasswordApi(token, temp, password).then(response=> {
      if(response.status !== 'ok') {
        alert(response.status);
      } else {
        alert('Your password has been updated');
        setShowPasswordReset(false);
      }
    });
  }


  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        { showPasswordReset ?

        <PasswordResetForm classes={classes} onCancel={()=>setShowPasswordReset(false)} setNewPassword={setNewPassword} /> :

         forgotPassword ?
          <ForgotPasswordForm classes={classes} onCancel={()=>setForgotPassword(false)} resetPassword={resetPassword} />
        :
        <React.Fragment>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            error={error}
            autoFocus
            value={email}
            onChange={e=>setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            helperText={errorText}
            error={error}
            value={password}
            onChange={e=>setPassword(e.target.value)}
          />
          <Button
            onClick={()=>authenticate(email,password)}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Login
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" onClick={()=>setForgotPassword(true)} variant="body2">
                Forgot password?
              </Link>
            </Grid>
          </Grid>
        </form>
        </React.Fragment>
        }
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
