import React, {useEffect, useState, useContext, useMemo} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Typography,Box,Avatar,Divider,Grid,Paper,TextField, Chip,FormLabel,IconButton,Tooltip } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment';

import { MessageList } from './Messages';
import ChatMessageBar from '../ChatMessageBar/ChatMessageBar';

//import ScheduleIcon from '@material-ui/icons/Schedule';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import TimerOffIcon from '@material-ui/icons/TimerOff';

import uuid from 'uuid';

import {
  sendMessage,
  getChatData,
  getAppointmentsForCustomer,
  stopCustomerAutomation
} from '../../Services/API';

import { SocketContext } from '../../Context/SocketContext';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(1),
    textAlign: 'left',
  },
  textField: {
    '&disabled': {color: 'red'},
    margin: '10px 0px'
  },
  chip: {
    margin: '10px 0px'
  },
  divider: {
    margin: '10px 0px',
  },
  paper: {
    padding: theme.spacing(1)
  },
  avatar: {
    width: '55px',
    height: '55px',
    backgroundColor: theme.palette.primary.main,
    marginRight: theme.spacing(1)
  },
  messageListContainer: {
    maxHeight: '800px',
    overflowY: 'scroll',
  },
  title: {
    flexDirection: 'row'
  },
  messageControls: {
    position: 'sticky',
    flexGrow: 0,
    bottom: '0',
    backgroundColor: theme.palette.common.white,
    padding: '10px 10px 10px 10px'
  }
}));

const TextEntry = ({value,label}) => {

  const classes = useStyles();

  return <TextField
          className={classes.textField}
          value={value}
          label={label}
          readOnly
          disabled
          fullWidth/>
}

const ContactCard = props => {

  const classes = useStyles();

  return (
    <div>
      <TextEntry value={props.name} label='Name'/>
      <TextEntry value={props.email} label='Email'/>
      <TextEntry value={props.phone} label='Phone'/>
      <TextEntry value={moment(props.created).format('lll')} label='Created'/>


      <FormLabel focused>Lead Source</FormLabel>
      <div>
        <Chip className={classes.chip} color='secondary' label={props.source}/>
      </div>


    </div>

  )
}

const AppointmentCard = props => {

  return (
    <div>
      <TextEntry label='Date' value={moment(props.date).format('lll')}/>
      <TextEntry label='Status' value={props.status}/>
      <TextEntry label='Schedule' value={props.automationTag}/>
    </div>
  )
}

const PagedCard = props => {
  const [index, setIndex] = useState(0);
  const { data } = props;

  return (
    <div>
      <AppointmentCard {...data[index]} />
      <Pagination count={data.length} onChange={(e,v)=>{setIndex(v-1)}}/>
    </div>
  )

}

const AutoUpdateMessageList = props => {

  const { chatData } = props;

  const [messages, setMessages] = useState([]);

  const scroller = () => {
    const elm = document.getElementById('ender');
    if(elm) {
      elm.scrollIntoView(true);
    }
  }

  useEffect(()=> {
    setMessages(chatData.messages);
  }, [chatData]);

  // useEffect(() => {
  //   scroller();
  // },[messages]);

  const socket = useContext(SocketContext);

  const updateMessage = (event) => {

    try {
      let socketMsg = JSON.parse(event.data);

      if(socketMsg.type === 'CHAT_MESSAGE') {

        setMessages(prev=> {

          setTimeout(scroller, 100);

          if(prev.length === 0) {
            return [socketMsg.payload];
          } else if(prev[0].chatId === socketMsg.payload.chatId) {
            return [...prev, socketMsg.payload];
          } else {
            return prev;
          }
        });


      } else {
        console.log(socketMsg.type);
      }
    } catch(e) {
      //eat
    }
  };

  useEffect(()=> {

    if(!socket) {
      return;
    }

    socket.addEventListener('message', updateMessage);

    return ()=> {
      socket.removeEventListener('message', updateMessage);
    };

  },[socket]);

  return <React.Fragment>
            <MessageList messages={messages}/>
             <div id='ender'/>
          </React.Fragment>
};

const Contact = props => {

  const {chatId, contact, token} = props;
  const [chatData, setChatData] = useState({messages: []});
  const [appointments, setAppointments] = useState([]);
  const [showXl, setShowXl] = useState(props.showXl);
  const classes = useStyles();

  useEffect(()=> {
    getChatData(token, chatId).then(r=>{
      if(r.status === 'Unauthorized') {
        setChatData({messages: []});
      } else {
        setChatData(r);
      }

    });

    getAppointmentsForCustomer(token,chatId).then(r=> {
      setAppointments(r.appointments);
    });

  },[token, chatId]);

  const upcommingAppointments = useMemo(()=> {
    const now = Date.now();
    return appointments.filter(a=>a.date > now);
  },[appointments]);

  const pastAppointments = useMemo(()=> {
    const now = Date.now();
    return appointments.filter(a=>a.date < now);
  },[appointments]);

  const sendMessageHandler = msg => {

      const payload = {
        id: uuid.v4(),
        chatId: chatData.id,
        phone: chatData.phone,
        text: msg,
        sender: chatData.name,
        messageType: 'message',
        timeStamp: new Date().getTime(),
        direction: 'outgoing'
      };

    sendMessage(token, payload);
  };

  const stopAutomation = ()=> {
      stopCustomerAutomation(token, contact.id).then(s=> {
        if(s.status === 'ok') {
          alert(`Automations stopped for ${contact.name}`);
        } else {
          alert('Unable to stop automations. Try again.');
        }
      });
  };

  return(
    <div className={classes.root}>
      <div >
        <Grid container spacing={1}>
        { showXl &&
        <Grid item xs={4}>
          <Paper variant="outlined" className={classes.paper}>
            <div className={classes.title}>
              <Typography  variant='h6'>Details</Typography>
              <Divider className={classes.divider} />
            </div>

            <ContactCard {...contact}/>
         </Paper>

         <Paper style={{marginTop: '20px'}} variant="outlined" className={classes.paper}>
            <div className={classes.title}>
              <Typography  variant='h6'>Upcoming Appointments</Typography>
              <Divider className={classes.divider} />
            </div>

            {
              upcommingAppointments.length === 0 ? <Typography>No Appointments</Typography> :
              <PagedCard data={upcommingAppointments}/>
            }

         </Paper>

        <Paper style={{marginTop: '20px'}} variant="outlined" className={classes.paper}>
            <div className={classes.title}>
              <Typography  variant='h6'>Past Appointments</Typography>
              <Divider className={classes.divider} />
            </div>

            {
              pastAppointments.length === 0 ? <Typography>No Appointments</Typography> :

              <PagedCard data={pastAppointments}/>
            }

         </Paper>
        </Grid>

        }
        <Grid item xs={showXl ? 8 : 12}>
          <Paper variant="outlined" className={classes.paper}>
            <div className={classes.title}>
              <Box display='flex'>
                <Box flexGrow={1}>
                <Typography style={{textAlign: 'center'}} variant='h6'>{contact.name}</Typography>
                </Box>
              <Box>
                <Tooltip title='Details'><IconButton onClick={()=>setShowXl(s=>!s)} size='small'><InfoOutlinedIcon/></IconButton></Tooltip>
                <Tooltip title='Stop Autotexts'><IconButton onClick={stopAutomation} size='small'><TimerOffIcon/></IconButton></Tooltip>
              </Box>
              </Box>
              <Divider className={classes.divider}/>
            </div>
            <div className={classes.messageListContainer}>
              <AutoUpdateMessageList chatData={chatData} chatId={chatId}/>

            </div>
            <div className={classes.messageControls}>
              <ChatMessageBar sendMessage={sendMessageHandler} />
            </div>
          </Paper>
        </Grid>
        </Grid>
      </div>

    </div>
  );
};


export default Contact;

