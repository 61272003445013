import React, { useState, useEffect } from 'react';

import Button from '@material-ui/core/Button';

import {
  Grid,
  IconButton,
  Typography,
}
from '@material-ui/core';

import { Dialog, Paper, NativeSelect } from '@material-ui/core';


import DateFnsUtils from '@date-io/date-fns';
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import moment from 'moment';

import AppHeader from '../../../../Components/AppHeader/AppHeaderLarge';
import ModalBase from '../../../../Components/Dialogs/ModalBase';
import BookAppointment from '../../Dialogs/BookAppointment/BookAppointment';
import Table from '../../../../Components/Table/Table';

import { getAppointmentForDate as getAppointmentForDateApi,
        updateAppointment as updateAppointmentApi } from '../../../../Services/API';


const Appointments = props => {

  const { token, user } = props;

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showBookAppointment, setShowBookAppointment] = useState(false);
  const [appointments, setAppointments] = useState([]);


  useEffect(() => {
    selectedDate.setHours(0,0,0,0);
    getAppointmentForDateApi(token, selectedDate.getTime()).then(r => {
      if (r.status === 'ok') {
        setAppointments(r.appointments);
      }
    });

  }, [token,selectedDate]);

  const getAppointments = () => {
    selectedDate.setHours(0,0,0,0);
    getAppointmentForDateApi(token, selectedDate.getTime()).then(r => {
      if (r.status === 'ok') {
        setAppointments(r.appointments);
      }
    });
  }

  return (
     <Paper style={{margin: '10px', padding: '10px', minHeight: '90vh'}}>
     <ModalBase title='Book Appointment' open={showBookAppointment} actions={<Button onClick={()=>setShowBookAppointment(false)}>Close</Button>}>
        <BookAppointment token={token} user={user} handleClose={()=>{getAppointments(); setShowBookAppointment(false)}}/>
      </ModalBase>
      <AppHeader onNew={()=>{setShowBookAppointment(true)}} label='Book Appointment' title='Appointments'>
      <Grid container direction='row' alignItems='center'>
        <Grid item>
          <IconButton size='medium' onClick={()=>setSelectedDate(moment(selectedDate).subtract(1,'days').toDate())}><ArrowLeftIcon/></IconButton>
         </Grid>
         <Grid item>
            <Typography>{moment(selectedDate).format('LL')}</Typography>
        </Grid>
        <Grid item>
          <IconButton size='medium' onClick={()=>setSelectedDate(moment(selectedDate).add(1,'days').toDate())}><ArrowRightIcon/></IconButton>
        </Grid>
      </Grid>
      </AppHeader>
      {
        appointments.length === 0 ? <Typography variant='h6'>No appointments for {moment(selectedDate).format('LL')}</Typography> :

        <Table
        title='Appointments'
        options={{
          pageSize: 10,
          pageSizeOptions: [10,15,20]
        }}
        columns={[
          {title: 'Name',field: 'name', editable: 'never'},
          {title: 'Date',field: 'date',

          editComponent: props => (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DateTimePicker
              fullWidth
              disabled={false}
              label="DateTimePicker"
              inputVariant="outlined"
              value={props.value}
              onChange={e=>props.onChange(e)}
            />
           </MuiPickersUtilsProvider>
          ),

          render: rowData => <span>{rowData.date ? moment(rowData.date).format('lll') : ''}</span>},
          {title: 'Appointment Status',field: 'status',

            editComponent: props => (
             <NativeSelect variant='outlined' value={props.value} onChange={e=>props.onChange(e.target.value)}>
              <option value=''>Select</option>
              <option value='CONFIRMED'>Confirmed</option>
              <option value='NO_SHOW'>No Show</option>
              <option value='SHOW'>Show</option>
              <option value='ENROLLED'>Enrolled</option>
             </NativeSelect>
            )

          },
        ]}
        data={appointments}
        editable={{
          onRowUpdateCancelled: rowData => console.log("Row editing cancelled"),
          onRowUpdate: (newData, oldData) => {

          console.log(newData);

          if(newData.date !== oldData.date) {
            return updateAppointmentApi(token, newData.id, newData.status, newData.date).then(r=>getAppointments());
          } else {
            return updateAppointmentApi(token, newData.id, newData.status, ).then(r=>getAppointments());
          }
          },

        }}
      />

      }
    </Paper>
  );

};

export default Appointments