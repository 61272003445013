import React, {useState} from 'react';

import {
  useHistory
} from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import ForumIcon from '@material-ui/icons/Forum';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import TodayIcon from '@material-ui/icons/Today';
import PeopleIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';


const useStyles = makeStyles((theme) => ({
  root: {

  },
 }));

const MenuItem = ({text, icon, onClick, isSelected}) => {

  return (
    <ListItem button onClick={onClick} selected={isSelected}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={text} />
   </ListItem>
  );
};

const menuItems = [
    {
      text: 'Messages',
      label: true,
    },
    {
      text: 'All Messages',
      icon: <ForumIcon/>,
      location:'/messages'
    },
    {
      text: 'Unread Messages',
      icon: <AnnouncementIcon/>,
      location: '/messages#unread',
    },
    {
      divider: true,
    },
     {
      text: 'Appointments',
      label: true,
    },
    {
      text: 'Appointments',
      icon: <TodayIcon/>,
      location: '/appointments'
    },
    {
      divider: true,
    },
     {
      text: 'Leads',
      label: true,
    },
    {
      text:'Leads',
      icon: <PeopleIcon/>,
      location: '/leads',
    },
    {
      text: 'Automations',
      label: true,
    },
     {
      text:'Automations',
      icon: <PeopleIcon/>,
      location: '/Automations',
    },
        {
      text: '',
      label: true,
    },
     {
      text:'Settings',
      icon: <SettingsIcon/>,
      location: '/Settings',
    }
  ]

const Menu = props => {

  const [location, setLocation] = useState('/messages');

  const classes = useStyles();
  const history = useHistory();

  const navigateToLocation = location => () => {
      setLocation(location);
      history.push(location);
  }

  return (
      <div className={classes.root}>
        <List dense>
         {
           menuItems.map((item,idx)=> {

             if(item.divider) {
               return null;
               //return <Divider key={`divider=${idx}`}/>;
             }

             if(item.label) {
               return <ListItem key={idx}><ListItemText primaryTypographyProps={{variant:'h6'}}>{item.text}</ListItemText></ListItem>
             }

             return <MenuItem key={item.text}
                      onClick={navigateToLocation(item.location)}
                      icon={item.icon}
                      isSelected={location===item.location}
                      text={item.text} />;
           })
         }
        </List>
      </div>

  );
}

export default Menu;