import React, { useState, useEffect } from 'react';

import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import Button from '@material-ui/core/Button';


import { bulkEmailContacts
} from '../../../../Services/API';


import {TextField, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
    //height: '600px'
  },
  textField: {
    margin: theme.spacing(1),
    width: '100%'
  },
  button: {
    marginTop: theme.spacing(2)
  }
}));


const BulkEmailMessage = props => {

  const [replyAddress, setReplyAddress] = useState('');
  const [subject, setSubject] = useState('');
  const [data, setData] = useState(EditorState.createEmpty());

  //token, replyTo, emails, subject, body
  const sendMessage = () => {

    if(replyAddress === '') {
      alert('No reply address specified');
      return;
    }

    if(subject === '') {
      alert('No subject specified');
      return;
    }

    bulkEmailContacts(props.token, replyAddress, props.emails, subject, draftToHtml(convertToRaw(data.getCurrentContent()))  ).then(r=>{

      if(r.status !== 'ok') {
        alert('There was a problem sending this email. Try again later');
      } else {
        props.onEmailSent();
      }
    });
  }

  const classes = useStyles();

  return (
    <React.Fragment>
      <Container className={classes.container} component='main' maxWidth='lg'>
        <div>
          <Button color='primary' onClick={props.onClose}>Cancel</Button>
          <Button variant='contained' color='primary' onClick={sendMessage}>Send</Button>
        </div>
        <TextField
          className={classes.textField}
          fullWidth
          variant='outlined'
          value={replyAddress}
          label='Reply-to Address'
          onChange={e=>setReplyAddress(e.target.value)}
        />
        <TextField
          fullWidth
          className={classes.textField}
          variant='outlined'
          value={subject}
          label='Subject'
          onChange={e=>setSubject(e.target.value)}
        />
        <Editor
            editorState={data}
            wrapperStyle={{height: '400px'}}
            editorStyle={{border: '1px #dedede solid', borderRadius: '4px'}}
            onEditorStateChange={setData}
          />

      </Container>
    </React.Fragment>
  );
};

export default BulkEmailMessage;