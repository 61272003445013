import React from 'react';

import { Toolbar, Fab, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

const AppHeader = props => {

  return (

    <Toolbar>
       <Fab variant="extended" onClick={props.onNew} style={{backgroundColor: 'white'}}>
        <AddIcon color='secondary'/>
        {props.label}
      </Fab>
      <Typography style={{flexGrow: 1}} variant='h4'>{props.title}</Typography>
      <div style={{flexShrink: 0}}>
        {props.children}
      </div>
    </Toolbar>

  );

};

export default AppHeader;